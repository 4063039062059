@media (max-width: $screen-lg) {
    // lg-md view
    .news-item { 
        .news-item-holder { 
            a {
                .image { 
                    height: 240px;
                }
            }
        }
    }
}

@media (max-width: $screen-md) {
    // md-view
    .news-plain-list {
        width: calc(100% + 20px);
        margin-left: -10px;
        margin-bottom: -20px;
        .news-item { 
            padding: 0 10px;
            margin: 0 0 20px;
            .news-item-holder { 
                a {
                    .desc { 
                        padding: 15px 20px 23px;
                    }
                }
            }
        }
    }

    .news-item { 
        .news-item-holder { 
            a {
                .image { 
                    height: 190px;
                }

                .desc { 
                    padding: 16px 25px 25px;
                    .date { 
                        font-size: 10px;
                        margin: 0 0 11px;
                    }

                    .name { 
                        height: 36px;
                        font-size: 14px;
                        line-height: 130%;
                        font-weight: 400;
                    }

                    .action { 
                        margin: 17px 0 0;
                        .text {
                            font-size: 12px;
                        }

                        .icon {

                        }
                    }
                }
            }
        }
    }
}
