@media (max-width: $screen-sm) {
    // sm-view
    .news-list-preview {
        overflow: hidden;
        .news-list-preview-holder {
            >.action {
                margin: 6px 0 0;
            }
        }
    }

    .news-plain-list {
        width: 100%;
        margin-left: 0;
        margin-bottom: -15px;
        .news-item { 
            width: 100%;
            padding: 0;
            margin: 0 0 15px;
            .news-item-holder { 
                a {
                    .image {
                        height: 160px;
                    }
                    .desc { 
                        padding: 11px 20px 16px;
                        .action {
                            margin: 18px 0 0;
                        }
                    }
                }
            }
        }
    }

    .news-slider {
        .slick-slider {
            .slick-list {
                overflow: visible;
                box-sizing: border-box;
                padding: 0 6.5% 0 0;
            }
        }
        .news-slider-holder {

        }
    }

    .news-item { 
        .news-item-holder { 
            a {
                .image { 
                    height: 154px;
                }

                .desc { 
                    padding: 12px 20px 12px;
                    .date {
                        font-size: 12px;
                        line-height: 17px;
                        margin: 0 0 6px;
                    }

                    .name { 
                        font-size: 16px;
                        line-height: 22px;
                        height: 44px;
                    }

                    .action {
                        margin: 24px 0 0;
                        .text {
                            font-size: 14px;
                        }

                        .icon {
                            width: 10px;
                            height: 7px;
                            margin-left: 5px;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
