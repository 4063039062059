@media (max-width: $screen-sm) {
    // sm-view
    .small-articles-block {
        .small-articles-block-holder { 
            width: 110.35%;
            margin-left: -5.175%;
            padding: 30px 5.175%;
            .heading {
                margin: 0 0 20px;
            }

            .small-articles-list { 
                .small-articles-list-holder { 
                    .small-article { 
                        &:not(:last-of-type) {
                            margin: 0 0 18px;
                            .small-article-holder { 
                                padding: 0;
                                background-color: transparent;
                                .image { 
                                    width: 100%;
                                    margin: 0 0 20px;
                                }

                                .text { 
                                    width: 101%;
                                    padding: 0;
                                }
                            }
                        }
                    }

                    .note {
                        .note-holder {
                            padding: 5px;
                            font-size: 12px;
                            line-height: 17px;
                            .icon {
                                svg {
                                    width: 16px;
                                    height: 16px;
                                }
                            }

                            .text {
                                width: calc(100% - 16px);
                                padding-left: 5px;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
