.breadcrumbs {
    display: inline-block;
    width: 100%;
    margin-top: 0;
    margin-bottom: 30px;
    .breadcrumbs-holder {
        display: inline-block;
        width: 100%;
        box-sizing: border-box;
        ul.breadcrumbs-list {
            display: inline-block;
            width: 100%;
            margin: 0;
            padding: 0;
            list-style: none;
            li {
                display: inline;
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                line-height: 16px;
                margin-right: 4px;
                color: $gray-base;
                padding: 0;
                a {
                    color: #D7D7D7;
                    text-decoration: none;
                    transition: all 300ms;
                    &:hover {
                        color: $brand-primary;
                        transition: all 300ms;
                    }
                }
                span {

                }
                &.sep {
                    color: #D7D7D7;
                    svg {
                        width: 8px;
                        height: 4px;
                        vertical-align: middle;
                    }
                }
            }
        }
    }
}

@import "../media/tablet/includes/breadcrumbs";
@import "../media/mobile/includes/breadcrumbs";
