@import "../parameters";
@import "../mixins";

.price-range {
    display: inline-block;
    width: 100%;
    box-sizing: border-box;
    margin-top: 2px;
    margin-bottom: 12px;
    .represent-text {
        display: inline-block;
        width: 100%;
        margin-top: 0;
        .represent-from, .represent-to {
            display: none;
        }
        .represent-to {

        }
    }
    .price-inputs {
        display: inline-block;
        width: calc(100% + 14px);
        margin-left: -10px;
        margin-bottom: 17px;
        position: relative;
        .sep {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 21px;
            color: #3D3D3D;
        }
        label {
            display: inline-block;
            float: left;
            width: 50%;
            box-sizing: border-box;
            padding: 0 10px;
            position: relative;
            .name {
                display: none;
            }
            input {
                display: flex;
                align-items: center;
                width: 100%;
                height: 44px;
                box-sizing: border-box;
                font-family: $main_font;
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 21px;
                color: #3D3D3D;
                border: 1px solid #BDBDBD;
                padding: 0 9px 0 11px;
                border-radius: 6px;
                background: white;
                transition: all 300ms;
                @include placeholder {
                    font-family: $main_font;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 21px;
                    color: #8B8B8B;
                };
                &:focus {
                    outline: none;
                    border-color: $brand-primary;
                    transition: all 300ms;
                }
            }
        }

    }
    .slider-holder {
        display: inline-block;
        display: inline-block;
        width: calc(100% + -24px);
        box-sizing: border-box;
        margin-left: 12px;
        .noUi-target {
            background: white;
            box-shadow: none;
            &.noUi-horizontal {
                height: 4px;
                .noUi-handle {
                    width: 24px;
                    height: 24px;
                    right: -12px;
                    top: -12px;
                    border: none;
                    border-radius: 50%;
                    background: $brand-secondary;
                    box-shadow: none;
                    box-shadow: 0px 6px 10px rgba(255, 80, 0, 0.15);
                    transition: all 300ms;
                    &:focus, &:hover {
                        outline: none;
                        transition: all 300ms;
                    }
                }
            }
            .noUi-base {
                background: $brand-secondary;
                border-radius: 1px;
            }
            .noUi-connect {
                background: $brand-secondary;
            }
            .noUi-handle {
                &::after, &::before {
                    content: none;
                }
            }
        }
    }
}
