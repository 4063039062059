@media (max-width: $screen-lg) {
    // lg-md view
}

@media (max-width: $screen-md) {
    // md-view
    .small-articles-block { 
        .small-articles-block-holder { 
            padding: 25px;
            .heading { 
                margin: 0 0 23px;
            }

            .small-articles-list {
                .small-articles-list-holder { 
                    .small-article { 
                        &:not(:last-of-type) {
                            margin: 0 0 18px;
                        }
                        .small-article-holder {
                            padding: 20px;
                            .image { 
                                width: 195px;
                            }

                            .text { 
                                width: calc(100% - 195px);
                                padding-left: 20px;
                            }
                        }
                    }

                    .note { 
                        .note-holder { 
                            font-size: 12px;
                            line-height: 17px;
                        }
                    }
                }
            }
        }
    }
}
