@media (max-width: $screen-lg) {
    // lg-md view
}

@media (max-width: $screen-md) {
    // md-view
    .local-search-block { 
        .local-search-block-holder { 
            .from { 
                form {
                    .form-field { 
                        .input { 
                            input { 
                                height: 50px;
                                font-size: 12px;
                                @include placeholder {
                                    font-size: 12px;
                                }
                            }

                            .addon { 

                                .btn { 
                                    width: 40px;
                                    height: 40px;
                                    line-height: 40px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
