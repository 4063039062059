@media (max-width: $screen-lg) {
    // lg-md view
}

@media (max-width: $screen-md) {
    // md-view
    .bulletin-tabs-block {
        .bulletin-tabs-block-holder { 
            padding: 23px 25px 25px;
            .bulletin-tabs-controlls { 
                margin: 0 0 20px;
                .bulletin-tabs-controlls-holder { 
                    .controller-link { 
                        margin: 0 4px 8px;
                        a {
                            font-size: 14px;
                            line-height: 130%;
                            padding: 11px 15px;
                        }
                    }
                }
            }

            .bulletin-tabs { 

            }
        }
    }

    .inner-tab { 
        .inner-tab-holder { 
            .link-buttons-block {
                .link-buttons-block-holder { 
                    padding: 27px 25px 25px;
                    .heading { 
                        margin: 0 0 20px;
                    }

                    .note { 
                        margin: 0 0 20px;
                    }

                    .button-links { 
                        .btn { 
                            margin-right: 5px;
                            margin-bottom: 5px;
                            font-size: 16px;
                            padding: 10px 10px;
                        }
                    }
                }
            }

            .iframe-block {
                .iframe-block-holder {
                    padding: 30px 25px;
                }
            }

            >.text.variant-2 {
                padding: 25px;
                h3, .h3 {
                    margin: 0 0 32px;
                }
    
                p {
                    &:not(:last-of-type) {
                        margin: 0 0 15px;
                    }
                }
    
                ul, ol {
                    margin-top: 15px;
                    padding-left: 25px;
                    margin-bottom: 20px;
                    li {
                        &:not(:last-of-type) {
                            margin: 0 0 10px;
                        }
                    }
                }
            }
            >.text {
                padding: 27px 25px 25px;
                .bkg-image {

                }

                .heading {
                    margin: 0 0 29px;
                }

                p {
                    &:not(:last-of-type) {
                        margin: 0 0 20px;
                    }
                }

                .note {
                    font-size: 12px;
                    margin: 17px 0 0;
                }

                >.action {
                    margin: 10px 0 0;
                    &.big-gap {
                        margin: 20px 0 0;
                    }
                }

                .text-group {
                    display: inline-block;
                    width: 100%;
                    &:not(:last-of-type) {
                        margin: 0 0 20px;
                    }
                }
            }

            .socials-links { 
                .text { 
                    font-size: 14px;
                    margin-right: 10px;
                }

                .links { 
                    margin: 0 -5px;
                    .link {
                        margin: 0 5px;
                        a {
                            width: 40px;
                            height: 40px;
                            line-height: 37px;
                            svg {
                                width: 21px;
                                height: auto;
                            }
                        }
                    }
                }
            }

            .about-text { 
                .about-text-holder { 
                    padding: 27px 25px 25px;
                    .image { 

                    }

                    .text-desc {
                        padding-left: 20px; 
                        .text-desc-holder { 
                            h3, .h3 {
                                margin: 0 0 20px;
                            }

                            p {

                            }

                            .author { 
                                margin: 15px 0 0;
                                .name { 
                                    margin: 0 0 15px;
                                }

                                .position { 

                                }
                            }

                            .action { 
                                margin: 20px 0 0;
                            }
                        }
                    }
                }
            }
        }
    }
}
