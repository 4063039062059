@media (max-width: $screen-lg) {
    // lg-md view
}

@media (max-width: $screen-md) {
    // md-view
    .gallery-block {
        .gallery-block-holder {
            .heading {
                margin: 0 0 20px;
                h3, .h3 {

                }

                .date {
                    font-size: 12px;
                    line-height: 17px;
                    padding: 0;
                }
            }
        }
    }
    .images-gallery.long {
        .images-gallery-holder { 
            .images-gallery-list { 
                .images-gallery-list-holder { 
                    .gallery-image { 
                        .gallery-image-holder { 
                            a {
                                .inner-image {
                                    margin-top: 108%;
                                }
                            }
                        }
                    }
                }
            }
        }
    } 
    .images-gallery { 
        .images-gallery-holder { 
            .images-gallery-list { 
                .images-gallery-list-holder { 
                    width: calc(100% + 20px);
                    margin-left: -10px;
                    margin-bottom: -20px;
                    .gallery-image { 
                        width: 25%;
                        padding: 0 10px;
                        margin: 0 0 20px;
                    }
                }
            }

            >.action {
                margin: 15px 0 0;
            }
        }
    }
}
