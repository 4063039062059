@media (max-width: $screen-lg) {
    // lg-md view
}

@media (max-width: $screen-md) {
    // md-view
    .banner-slider {
        .nav {
            ul.slick-dots {
                bottom: 5px;
            }
        }
    }
}
