.login-link-block { 
    display: inline-block;
    width: 100%;
	.login-link-block-holder { 
        display: inline-block;
        width: 100%;
		.login-link { 
            display: inline-block;
            width: 100%;
			.login-link-holder { 
                display: inline-block;
                width: 100%;
				a { 
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    background-color: $bg-secondary;
                    overflow: hidden;
                    color: $gray-base;
                    box-sizing: border-box;
                    padding: 28px 20px;
                    &:hover {
                        color: $brand-primary;
                    }
					.icon { 
                        width: 80px;
                        height: 80px;
                        margin-right: 25px;
						.icon-holder { 
                            width: 100%;
                            height: 100%;
							svg { 
                                width: 100%;
                                height: 100%;
							}
						}
					}

					.text { 
						.text-holder { 
                            padding: 0 0 4px;
							.heading { 
                                margin: 0 0 8px;
							}

							.desc { 
                                color: $gray;
							}
						}
					}
				}
			}
		}
	}
}

@import "../../media/tablet/includes/common/login_link";
@import "../../media/mobile/includes/common/login_link";
