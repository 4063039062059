.not-found { 
    display: inline-block;
    width: 100%;
    min-height: 464px;
    .not-found-holder { 
        width: 69.666%;
        margin: 0 auto;
        padding: 72px 0 72px;
        >.text { 
            float: left;
            width: calc(100% - 528px);
            .text-holder { 
                display: inline-block;
                width: 100%;
                box-sizing: border-box;
                padding: 0 21% 0 0;
                h1, .h1 { 
                    margin: 0 0 17px;
                }

                p { 
                    color: $gray;
                }

                .link { 
                    display: inline-block;
                    width: 100%;
                    margin: 21px 0 0;
                    a { 
                        display: inline-flex;
                        align-items: center;
                        font-size: 14px;
                        line-height: 20px;
                        &:hover {
                            color: $brand-primary-accent;
                            transition: all 0.3s cubic-bezier(0.45, 0, 0.55, 1);
                            .icon { 
                                color: $brand-primary-accent;
                                transform: translateX(5px);
                                transition: all 0.3s cubic-bezier(0.45, 0, 0.55, 1);
                            }
                        }
                        .text { 

                        }

                        .icon { 
                            margin-left: 10px;
                            width: 16px;
                            height: 10px;
                            color: $brand-primary;
                            transition: all 0.3s cubic-bezier(0.45, 0, 0.55, 1);
                            svg { 
                                width: 100%;
                                height: auto;
                            }
                        }
                    }
                }
            }
        }

        .image { 
            float: left;
            width: 528px;
            margin: 29px 0 0 0;
            .image-holder { 
                display: inline-block;
                width: 100%;
                svg { 
                    width: 100%;
                    height: auto;
                    path { 

                    }
                }
            }
        }
    }
}

@import "../../media/tablet/includes/common/404";
@import "../../media/mobile/includes/common/404";
