.small-articles-block { 
    display: inline-block;
    width: 100%;
	.small-articles-block-holder { 
        display: inline-block;
        width: 100%;
        box-sizing: border-box;
        padding: 30px;
		.heading { 
            display: inline-block;
            width: 100%;
            margin: 0 0 29px;
			h3, .h3 { 

			}
		}

		.small-articles-list { 
            display: inline-block;
            width: 100%;
			.small-articles-list-holder { 
                display: inline-block;
                width: 100%;
				.small-article { 
                    display: inline-block;
                    width: 100%;
                    &:not(:last-of-type) {
                        margin: 0 0 28px;
                    }
					.small-article-holder { 
                        display: inline-block;
                        width: 100%;
                        background-color: white;
                        box-sizing: border-box;
                        padding: 30px;
						.image { 
                            float: left;
                            width: 400px;
							.image-holder { 
                                display: inline-block;
                                width: 100%;
								img { 
                                    display: inline-block;
                                    width: 100%;
								}
							}
						}

						.text { 
                            float: left;
                            width: calc(100% - 400px);
                            box-sizing: border-box;
                            padding-left: 30px;
							.text-holder { 
                                display: inline-block;
                                width: 100%;
								p { 
                                    display: inline-block;
                                    width: 100%;
								}
							}
						}
					}
				}

				.note { 
                    display: inline-block;
                    width: 100%;
					.note-holder { 
                        display: inline-block;
                        width: 100%;
                        background-color: white;
                        border-radius: 10px;
                        color: $brand-primary;
                        box-sizing: border-box;
                        padding: 15px;
                        font-size: 14px;
                        line-height: 20px;
						.icon { 
                            float: left;
							svg { 
                                width: 24px;
                                height: 24px;
							}
						}

						.text { 
                            float: left;
                            width: calc(100% - 24px);
                            box-sizing: border-box;
                            padding-left: 10px;
						}
					}
				}
			}
		}
	}
}

@import "../../media/tablet/includes/common/small_articles";
@import "../../media/mobile/includes/common/small_articles";
