@media (max-width: $screen-lg) {
    // lg-md view
}

@media (max-width: $screen-md) {
    // md-view
    .division-detail-block {
        .division-detail-block-holder {
            >.back {
                margin: 0 0 20px;
                a {
                    padding: 9.5px 19px;
                    .icon {
                        padding: 5px 0 0;
                    }
                }
            }
            >.heading {
                margin: 0 0 18px;
            }
        }
    }

    .division-detail-card {
        .division-detail-card-holder {
            padding: 20px;
            .card-info { 
                .card-info-holder { 
                    padding: 25px;
                    .image {
                        width: 123px;
                    }

                    .desc { 
                        width: calc(100% - 123px);
                        padding-left: 30px;
                        .desc-holder { 
                            .heading {

                            }

                            .info-texts {
                                margin: 0 0 20px;
                            }
                        }
                    }
                }
            }
        }
    }

    .division-personel {
        .division-personel-holder {
            padding: 20px;
            .heading {
                margin: 0 0 20px;
            }

            .division-personel-overview {
                .division-personel-overview-holder {
                    padding: 25px;
                    .image {

                    }

                    .personel-overview {
                        .personel-overview-holder {
                            .personel {
                                .personel-holder {
                                    .position {
                                        margin: 0 0 10px;
                                    }

                                    .name {

                                    }
                                }
                            }
                        }
                    }
                }
            }

            .personel-detail-info {
                .personel-detail-info-holder  {
                    padding: 25px;
                    .personel-image {
                        width: 175px;
                        .personel-image-holder {

                        }
                    }

                    .personel-info {
                        width: calc(100% - 175px);
                        padding-left: 20px;
                        .personel-info-holder {
                            .position {
                                height: auto;
                                margin: 0 0 10px;
                            }

                            .name {

                            }

                            .info-contacts { 
                                margin: 10px 0 0;
                            }

                            >.text {
                                margin: 20px 0 0;
                            }
                        }
                    }
                }
            }
        }
    }
}
