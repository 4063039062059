@media (max-width: $screen-sm) {
    // sm-view
    .iconed-links-block { 
        .iconed-links-block-holder { 
            .cols {
                .col {
                    &.col-custom-50-minus-290 {
                        width: 100%;
                    }
                    &.col-custom-290 {
                        width: 100%;
                    }
                }
            }

            .link-item { 
                .link-item-holder { 
                    .link { 
                        padding: 15px 20px 17px;
                        .image { 

                        }

                        >.text { 
                            .text-holder { 
                                .heading { 

                                }

                                .desc { 
                                    font-size: 12px;
                                    line-height: 17px;
                                    margin: 7px 0 0;
                                    &.lines-2 {
                                        height: auto;
                                        -webkit-line-clamp: 3;
                                        line-clamp: 3;
                                    }
                                }

                                .big-action { 
                                    .btn { 
                                        
                                    }
                                }

                                .big-action {
                                    margin: 15px 0 0;
                                    .btn {
                                        padding: 9.5px 15px;
                                    }
                                }

                                .action { 
                                    font-size: 14px;
                                    margin: 18px 0 0;
                                    .text {

                                    }

                                    .icon { 
                                        width: 10px;
                                        height: 7px;
                                        margin-left: 5px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
