@media (max-width: $screen-sm) {
    // sm-view
    .common-section-header { 
        .common-section-header-holder { 
            .text {

            }

            .action { 

                a { 
                    font-size: 16px;
                    line-height: 22px;
                    .text {

                    }

                    .icon {
                        width: 10px;
                        height: 7px;
                        margin-left: 5px;
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
