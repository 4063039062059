@media (max-width: $screen-sm) {
    // sm-view
    .gallery-block {
        float: left;
        .gallery-block-holder {
            width: 110.35%;
            margin-left: -5.175%;
            padding: 27px 5.175% 15px;
            .heading {
                flex-direction: column-reverse;
                h3, .h3 {
                    max-width: none;
                    width: 100%;
                }

                .date {
                    width: 100%;
                    font-size: 14px;
                    line-height: 20px;
                    margin: 0 0 4px;
                }
            }
        }
    }
    .images-gallery.long {
        .images-gallery-holder { 
            .images-gallery-list { 
                .images-gallery-list-holder { 
                    .gallery-image { 
                        .gallery-image-holder { 
                            a {
                                .inner-image {
                                    margin-top: 104%;
                                }
                            }
                        }
                    }
                }
            }
        }
    } 
    .images-gallery { 
        .images-gallery-holder { 
            .images-gallery-list.row-4 {
                .images-gallery-list-holder { 
                    .gallery-image { 
                        width: 100%;
                    }
                }
            }
            .images-gallery-list { 
                .images-gallery-list-holder { 
                    width: 100%;
                    margin-left: 0;
                    margin-bottom: -20px;
                    &.slick-slider {
                        margin-left: -7.5px;
                        width: calc(100% + 15px);
                        box-sizing: border-box;
                        padding: 0 6.5% 0 0;
                        .slick-list {
                            overflow: visible;
                        }
                    }
                    .gallery-image { 
                        width: 100%;
                        padding: 0;
                        margin: 0 0 20px;
                        .gallery-image-holder { 
                            a {
                                .inner-image {
                                    margin-top: 107%;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
