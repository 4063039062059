@media (max-width: $screen-sm) {
    // sm-view
    .plaque-links-block.xs-bigger-plaque {
        .plaque-links-block-holder {
            .plaque-link { 
                .plaque-link-holder { 
                    a {
                        padding: 17px 20px 20px;
                    }
                }
            }
        }
    }
    .plaque-links-block {
        .plaque-links-block-holder {
            .plaque-link.strong {
                .plaque-link-holder { 
                    a {
                        font-size: 16px;
                        .action { 
                            font-size: 14px;
                        }
                    }
                }
            }
            .plaque-link { 
                .plaque-link-holder { 
                    a {
                        font-size: 16px;
                        line-height: 22px;
                        padding: 13px 20px 15px;
                        .name { 
                            margin: 0 0 10px;
                            &.lines-2 {
                                min-height: 32px;
                                margin: 0 0 15px;
                            }
                        }

                        .action { 
                            font-size: 14px;
                            .icon { 
                                width: 11px;
                                height: 6px;
                                margin-left: 5px;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
