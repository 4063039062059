@media (max-width: $screen-lg) {
    // lg-md view
}

@media (max-width: $screen-md) {
    // md-view
    .breadcrumbs {
        margin-bottom: 20px;
    }
}
