@media (max-width: $screen-sm) {
    // sm-view
    .empty-search {
        .empty-search-holder {
            padding: 35px 0;
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
