@media (max-width: $screen-sm) {
    // sm-view
    .banner-slider {
        .nav {
            ul.slick-dots {
                bottom: 0;
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
