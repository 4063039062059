.bulletin-section {
    display: inline-block;
    width: 100%;
    .bulletin-section-holder {
        display: inline-block;
        width: 100%;
    }
}

.bulletin-tabs-block { 
    display: inline-block;
    width: 100%;
	.bulletin-tabs-block-holder { 
        display: inline-block;
        width: 100%;
        background-color: $bg-secondary;
        box-sizing: border-box;
        padding: 26px 30px 30px;
		.bulletin-tabs-controlls { 
            display: inline-block;
            width: 100%;
            margin: 0 0 10px;
			.bulletin-tabs-controlls-holder { 
                display: flex;
                flex-wrap: wrap;
                width: calc(100% + 10px);
                margin: 0 -5px;
				.controller-link { 
                    float: left;
                    margin: 0 4px 10px;
					a { 
                        font-size: 16px;
                        line-height: 140%;
                        font-weight: 400;
                        padding: 11.5px 20px;
                        &.current {
                            background-color: $brand-primary;
                            border-color: $brand-primary;
                            color: white;
                            pointer-events: none;
                            touch-action: none;
                        }
					}
				}
			}
		}

		.bulletin-tabs { 
            display: inline-block;
            width: 100%;
			.tabs { 
                display: inline-block;
                width: 100%;
				.tab { 
                    display: inline-block;
                    width: 100%;
					
				}
			}
		}
	}
}

.inner-tab { 
    display: inline-block;
    width: 100%;
    .inner-tab-holder { 
        display: inline-block;
        width: 100%;
        .iconed-links-block { 
            margin: 0 0 20px;
            .iconed-links-block-holder { 
                .link-item { 
                    .link-item-holder { 
                        .link { 
                            padding: 23px 26px 26px;
                        }
                    }
                }
            }
        }
        .link-buttons-block {
            display: inline-block;
            width: 100%;
            .link-buttons-block-holder { 
                display: inline-block;
                width: 100%;
                background-color: white;
                box-sizing: border-box;
                padding: 38px 40px 40px;
                overflow: hidden;
                .heading { 
                    display: inline-block;
                    width: 100%;
                    margin: 0 0 25px;
                    h3, .h3 { 
        
                    }
                }
        
                .note { 
                    display: inline-block;
                    width: 100%;
                    color: $gray;
                    font-weight: 400;
                    margin: 0 0 25px;
                }
        
                .button-links { 
                    display: inline-block;
                    width: calc(100% + 15px);
                    margin-bottom: -16px;
                    .btn { 
                        float: left;
                        margin-right: 12px;
                        margin-bottom: 16px;
                        background-color: $bg-secondary;
                        border-color: $bg-secondary;
                        font-weight: 400;
                        padding: 10px 25px;
                        font-size: 18px;
                        &:hover {
                            background-color: $brand-primary;
                            border-color: $brand-primary;
                            color: white;
                        }
                    }
                }
            }
        }

        .iframe-block {
            display: inline-block;
            width: 100%;
            .iframe-block-holder {
                display: inline-block;
                width: 100%;
                background-color: white;
                overflow: hidden;
                box-sizing: border-box;
                padding: 80px 30px;
                .inner-holder {
                    display: inline-block;
                    width: 100%;
                    text-align: center;
                    >img {
                        max-width: 100%;
                    }
                }
            }
        }

        >.text.variant-2 {
            padding: 30px;
            h3, .h3 {
                margin: 0 0 30px;
            }

            p {
                &:not(:last-of-type) {
                    margin: 0 0 30px;
                }
            }

            ul, ol {
                margin-top: 17px;
                padding-left: 25px;
                margin-bottom: 30px;
                li {
                    &:not(:last-of-type) {
                        margin: 0 0 11px;
                    }
                }
            }
        }

        >.text {
            display: inline-block;
            width: 100%;
            box-sizing: border-box;
            padding: 37px 40px 40px;
            background-color: white;
            position: relative;
            z-index: 2;

            .image {
                padding-bottom: 30px;
                &.left {
                    float: left;
                    padding-right: 30px;
                }

                &.right {
                    float: right;
                    padding-left: 30px;
                }

                &.full {
                    width: 100%;
                    img {
                        width: 100%;
                    }
                }
            }
            
            .bkg-image {
                position: absolute;
                z-index: 1;
                right: 123px;
                top: 34px;
            }
            .heading {
                margin: 0 0 24px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                .heading-text {

                }

                .author {
                    white-space: nowrap;
                }
            }

            h3, .h3 {
                margin: 0 0 25px;
            }

            h4, .h4 {
                margin: 0 0 15px;
            }
            
            p {
                &.no-margin {
                    margin: 0;
                    &:not(:last-of-type) {
                        margin: 0;
                    }
                }
                &:not(:last-of-type) {
                    margin: 0 0 24px;
                }
            }

            ul, ol {
                padding-left: 27px;
                li {
                    &:not(:last-of-type) {
                        margin: 0 0 10px;
                    } 
                }
            }

            >.action {
                display: inline-block;
                width: 100%;
                margin: 14px 0 0;
                &.big-gap {
                    margin: 25px 0 0;
                }
                .btn {
                    min-width: 240px;
                    text-align: center;
                }
            }

            .note {
                display: inline-flex;
                align-items: center;
                padding: 10px;
                background-color: $bg-secondary;
                border-radius: 100px;
                color: $brand-primary;
                font-size: 14px;
                line-height: 140%;
                margin: 26px 0 0;
                &.bigger-padding {
                    padding: 15px;
                }
                &.no-margin {
                    margin: 0;
                }
                .icon {
                    width: 24px;
                    height: 24px;
                    svg {
                        width: 100%;
                        height: 100%;
                    }
                }
    
                .text {
                    width: calc(100% - 24px);
                    box-sizing: border-box;
                    padding-left: 10px;
                }
            }

            .text-group {
                display: inline-block;
                width: 100%;
                &:not(:last-of-type) {
                    margin: 0 0 40px;
                }
            }

            .info-text {
                display: inline-block;
                width: 100%;
                p {
                    color: $gray;
                    &:not(:last-of-type) {
                        margin: 0 0 15px;
                    }
                }

                a {
                    text-decoration: none;
                }
            }

            .small-text {
                width: 100%;
                margin: 0 0 20px;
                color: $gray;
                p {
                    &:not(:last-of-type) {
                        margin: 0 0 9px;
                    }
                }
                ul, ol {
                    line-height: 140%;
                    margin-top: 16px;
                    margin-bottom: 21px;
                    li {
                        &:not(:last-of-type) {
                            margin: 0 0 4px;
                        }
                    }
                }
            }
        }

        .reviews-slider {
            width: 100%;
            padding-bottom: 45px;
            margin-bottom: 18px;
            .nav {
                ul.slick-dots {
                    bottom: 0;
                }
            }
            .reviews-slider-holder {

                .review-slide-item {
                    width: 100%;
                    .review-slide-item-holder {
                        width: 100%;
                        height: 418px;
                        .image {
                            width: 100%;
                            height: 100%;
                            text-align: center;
                            img {
                                height: 100%;
                                max-width: 100%;
                                object-fit: contain;
                            }
                        }
                    }
                }
            }
        }

        .socials-links { 
            display: flex;
            align-items: center;
            width: 100%;
            .text { 
                float: left;
                font-size: 16px;
                margin-right: 16px;
            }
        
            .links { 
                float: left;
                margin: 0 -10px;
                .link { 
                    float: left;
                    margin: 0 10px;
                    a {
                        display: inline-block; 
                        width: 48px;
                        height: 48px;
                        line-height: 44px;
                        border-radius: 50%;
                        background-color: $brand-primary;
                        color: white;
                        text-align: center;
                        &:hover {
                            background-color: $brand-primary-accent;
                        }
                        svg { 
                            vertical-align: middle;
                        }
                    }
                }
            }
        }

        .about-text { 
            display: inline-block;
            width: 100%;
            .about-text-holder { 
                display: inline-block;
                width: 100%;
                box-sizing: border-box;
                padding: 37px 40px 40px;
                background-color: white;
                .image { 
                    width: 298px;
                    float: left;
                    .image-holder { 
                        display: inline-block;
                        width: 100%;
                        img { 
                            display: inline-block;
                            width: 100%;
                        }
                    }
                }
        
                .text-desc { 
                    width: calc(100% - 298px);
                    float: left;
                    box-sizing: border-box;
                    padding-left: 25px;
                    .text-desc-holder { 
                        display: inline-block;
                        width: 100%;
                        h3, .h3 { 
                            margin: 0 0 30px;
                        }
        
                        p { 
                            color: $gray;
                        }
        
                        .author { 
                            display: inline-block;
                            width: 100%;
                            margin: 21px 0 0;
                            .name { 
                                display: inline-block;
                                width: 100%;
                                font-weight: bold;
                                margin: 0 0 17px;
                            }
        
                            .position { 
                                display: inline-block;
                                width: 100%;
                                color: $gray;
                            }
                        }
        
                        .action { 
                            display: inline-block;
                            width: 100%;
                            margin: 35px 0 0;
                            .btn { 
                                min-width: 239px;
                                text-align: center;
                                padding: 9.5px 10px;
                            }
                        }
                    }
                }
            }
        }
        
    }
}

@import "../../media/tablet/includes/common/bulletin";
@import "../../media/mobile/includes/common/bulletin";
