//
// Variables
// --------------------------------------------------

//== Colors
//
//## Gray and brand colors for use across Bootstrap.

$gray-base:                 #1D1D1B !default;
$gray-darker:               lighten($gray-base, 20%) !default;
$gray-dark:                 lighten($gray-base, 35%) !default; 
$gray:                      #666666 !default;
$gray-light:                #F8F8F8 !default;
$gray-lighter:              #939393 !default;

$brand-primary:             #056256 !default;
$brand-primary-accent:      lighten($brand-primary, 20%) !default;
$brand-secondary:           white !default;
$brand-secondary-accent:    $gray-light !default;
$brand-success:             #5cb85c !default;
$brand-info:                #5bc0de !default;
$brand-warning:             #f0ad4e !default;
$brand-danger:              #d9534f !default;

$bg-primary:                white !default;
$bg-secondary:              $gray-light!default;

//== Media queries breakpoints
//
//## Define the breakpoints at which your layout will change, adapting to different screen sizes.

// Extra small screen / phone
//** Deprecated `$screen-xs` as of v3.0.1
$screen-xs:                  479px !default;
//** Deprecated `$screen-xs-min` as of v3.2.0
$screen-xs-min:              $screen-xs !default;
//** Deprecated `$screen-phone` as of v3.0.1
$screen-phone:               $screen-xs-min !default;

// Small screen / tablet
//** Deprecated `$screen-sm` as of v3.0.1
$screen-sm:                  767px !default;
$screen-sm-min:              $screen-sm !default;
//** Deprecated `$screen-tablet` as of v3.0.1
$screen-tablet:              $screen-sm-min !default;

// Medium screen / desktop
//** Deprecated `$screen-md` as of v3.0.1
$screen-md:                  1279px !default;
$screen-md-min:              $screen-md !default;
//** Deprecated `$screen-desktop` as of v3.0.1
$screen-desktop:             $screen-md-min !default;

// Large screen / wide desktop
//** Deprecated `$screen-lg` as of v3.0.1
$screen-lg:                  1470px !default;
$screen-lg-min:              $screen-lg !default;
//** Deprecated `$screen-lg-desktop` as of v3.0.1
$screen-lg-desktop:          $screen-lg-min !default;

// So media queries don't overlap when required, provide a maximum
$screen-xs-max:              ($screen-sm-min - 1) !default;
$screen-sm-max:              ($screen-md-min - 1) !default;
$screen-md-max:              ($screen-lg-min - 1) !default;

//== Fonts
//
//## define site fonts
$main_font: 'PT Sans', Helvetica, Arial, sans-serif !default;
$accent_font: 'AustinCyr', Helvetica, Arial, sans-serif !default;

//== Typography
//
//## Font, line-height, and color for body text, headings, and more.

$font-family-sans-serif:  $main_font;
$font-family-serif:       $main_font !default;
//** Default monospace fonts for `<code>`, `<kbd>`, and `<pre>`.
$font-family-monospace:   $main_font !default;
$font-family-base:        $font-family-sans-serif !default;

$font-size-base:          16px !default;
$font-size-large:         ceil(($font-size-base * 1.125)) !default; // ~18px
$font-size-small:         ceil(($font-size-base * .75)) !default; // ~12px

$font-size-h1:            58px !default; // ~32px
$font-size-h2:            38px !default; // ~24px
$font-size-h3:            24px !default; // ~21px
$font-size-h4:            18px !default; // ~18px
$font-size-h5:            $font-size-base !default;
$font-size-h6:            ceil(($font-size-base * .75)) !default; // ~12px

// md fontsizes

$font-size-base-md:          14px !default;
$font-size-large-md:         ceil(($font-size-base-md * 1.125)) !default; // ~18px
$font-size-small-md:         ceil(($font-size-base-md * .75)) !default; // ~12px

$font-size-h1-md:            38px !default; // ~32px
$font-size-h2-md:            28px !default; // ~24px
$font-size-h3-md:            18px !default; // ~21px
$font-size-h4-md:            16px !default; // ~18px
$font-size-h5-md:            $font-size-base-md !default;
$font-size-h6-md:            ceil(($font-size-base-md * .75)) !default; // ~12px

// xs fonsizes

$font-size-base-xs:          14px !default;
$font-size-large-xs:         16px !default; // ~18px
$font-size-small-xs:         12px !default; // ~12px

$font-size-h1-xs:            20px !default; // ~32px
$font-size-h2-xs:            18px !default; // ~24px
$font-size-h3-xs:            16px !default; // ~21px
$font-size-h4-xs:            14px !default; // ~18px
$font-size-h5-xs:            $font-size-base !default;
$font-size-h6-xs:            ceil(($font-size-base-xs * .75)) !default; // ~12px

//** Unit-less `line-height` for use in components like buttons.
$line-height-base:        1.4 !default; // 120%
$line-height-base-md:     1.4 !default; // 120%
$line-height-base-xs:     1.4 !default; // 120%
//** Computed "line-height" (`font-size` * `line-height`) for use with `margin`, `padding`, etc.
$line-height-computed:    floor(($font-size-base * $line-height-base)) !default; // ~19px
$line-height-computed-md: floor(($font-size-base-md * $line-height-base-md)) !default;
$line-height-computed-xs: floor(($font-size-base-xs * $line-height-base-xs)) !default;

//** By default, this inherits from the `<body>`.
$headings-font-family:    inherit !default;
$headings-font-weight:    700 !default;
$headings-line-height:    1.1 !default;
$headings-line-height-md: 1.1 !default;
$headings-line-height-xs: 1.1 !default;
$headings-color:          inherit !default;

//== Grid system
//
//## Define your custom responsive grid.

//** Number of columns in the grid.
$grid-columns:              12 !default;
//** Padding between columns. Gets divided in half for the left and right.
$grid-gutter-width:         30px !default;
// Navbar collapse
//** Point at which the navbar becomes uncollapsed.
$grid-float-breakpoint:     $screen-sm-min !default;
//** Point at which the navbar begins collapsing.
$grid-float-breakpoint-max: ($grid-float-breakpoint - 1) !default;

//== Form states and alerts
//
//## Define colors for form feedback states and, by default, alerts.

$state-success-text:             #3c763d !default;
$state-success-bg:               #dff0d8 !default;
$state-success-border:           darken(adjust-hue($state-success-bg, -10), 5%) !default;

$state-info-text:                #31708f !default;
$state-info-bg:                  #d9edf7 !default;
$state-info-border:              darken(adjust-hue($state-info-bg, -10), 7%) !default;

$state-warning-text:             #8a6d3b !default;
$state-warning-bg:               #fcf8e3 !default;
$state-warning-border:           darken(adjust-hue($state-warning-bg, -10), 5%) !default;

$state-danger-text:              #a94442 !default;
$state-danger-bg:                #f2dede !default;
$state-danger-border:            darken(adjust-hue($state-danger-bg, -10), 5%) !default;

//== Type
//
//##

//** Horizontal offset for forms and lists.
$component-offset-horizontal: 180px !default;
//** Text muted color
$text-muted:                  $gray-light !default;
//** Abbreviations and acronyms border color
$abbr-border-color:           $gray-light !default;
//** Headings small color
$headings-small-color:        $gray-light !default;
//** Blockquote small color
$blockquote-small-color:      $gray-light !default;
//** Blockquote font size
$blockquote-font-size:        ($font-size-base * 1.25) !default;
//** Blockquote border color
$blockquote-border-color:     $gray-lighter !default;
//** Page header border color
$page-header-border-color:    $gray-lighter !default;
//** Width of horizontal description list titles
$dl-horizontal-offset:        $component-offset-horizontal !default;
//** Point at which .dl-horizontal becomes horizontal
$dl-horizontal-breakpoint:    $grid-float-breakpoint !default;
//** Horizontal line color.
$hr-border:                   $gray-lighter !default;


//== Sizes
//
//## define var for sizes
$header-size: 90px;
$footer-size: 995px;
$footer-size-md: 963px;
$footer-size-xs: 1977px;
