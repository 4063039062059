@media (max-width: $screen-sm) {
    // sm-view
    .division-history-block { 
        .division-history-block-holder { 
            padding: 0;
            overflow: visible;
            >.text {
                h3, .h3 { 
                }

                p { 
                    &:not(:last-of-type) {
                        margin: 0 0 10px;
                    }
                }
            }

            .historical-reference { 
                .historical-reference-holder { 
                    padding: 0;
                    .image { 
                        max-width: none;
                        width: 100%;
                        &.left {
                            float: left;
                            padding: 0 0 20px;
                        }
                        &.right {
                            float: right;
                            padding: 0 0 20px;
                        }

                        .desc { 
                            margin: 10px 0 0;
                        }
                    }

                    p {
                        &:not(:last-of-type) {
                            margin: 0 0 10px;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
