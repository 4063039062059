.common-single-text-block.with-side-image {
    .common-single-text-block-holder { 
        .image {
            width: 400px;
            float: left;
            .image-holder {
                display: inline-block;
                width: 100%;
                overflow: hidden;
                img {
                    width: 100%;
                }
            }
        }

        .text { 
            width: calc(100% - 400px);
            box-sizing: border-box;
            padding-left: 30px;
            .text-holder { 
            }
        }
    }
}
.common-single-text-block { 
    display: inline-block;
    width: 100%;
	.common-single-text-block-holder { 
        display: inline-block;
        width: 100%;
        box-sizing: border-box;
        padding: 30px;
        .inline-link-list {
            ul, ol {
                color: $brand-primary;
                margin-bottom: 0;
                &:not(:last-of-type) {
                    margin-bottom: 15px;
                }
                li {
                    margin: 0;
                    &:not(:last-of-type) {
                        margin: 0 0 15px;
                    }
                }
            }
        }
        .text { 
            float: left;
            width: 100%;
            .text-holder { 
                display: inline-block;
                width: 100%;
                h3, .h3 { 
                    margin: 0 0 30px;
                }

                p { 

                }

                ul, ol {
                    margin: 0;
                    &:not(:last-child) {
                        margin: 0 0 15px;
                    }
                    li {
                        margin: 0;
                        &:not(:last-of-type) {
                            margin: 0 0 10px;
                        }
                    }
                }

                .external-link { 
                    display: inline-block;
                    width: 100%;
                    margin: 35px 0 0;
                    a { 
                        font-size: 18px;
                    }
                }

                .action {
                    display: inline-block;
                    width: 100%;
                    margin: 40px 0 0;
                    .btn {
                        min-width: 212px;
                        text-align: center;
                    }
                }
            }
        }
    }
}

@import "../../media/tablet/includes/common/text_block";
@import "../../media/mobile/includes/common/text_block";
