@media (max-width: $screen-lg) {
    // lg-md view
    .application-form {
        .application-form-holder {
            form {
                width: 65.3%;
            }
        }
    }
}

@media (max-width: $screen-md) {
    // md-view
    .application-form {
        .application-form-holder {
            padding: 40px 20px;
            form {
                width: 100%;
                padding: 25px 30px;
            }
        }
    }
}
