.common-form-block {
    display: inline-block;
    width: 100%;
    .common-form-block-holder {
        float: left;
        max-width: 845px;
        .note {
            display: inline-block;
            width: 100%;
            color: $gray;
            line-height: 140%;
            margin: -3px 0 28px;
            a {
                color: $brand-primary;
                text-decoration: underline;
                word-break: break-all;
                text-decoration-color: currentColor;
                &:hover {
                    color: $brand-primary-accent;
                    text-decoration-color: transparent;
                }
            }
        }
        .form {
            display: inline-block;
            width: 100%;
            form { 

                .form-group { 
            
                    .form-col { 
            
                        .form-field { 
                            margin-bottom: 15px;
                            .input { 
            
                                .name { 
            
                                }
            
                                input { 
            
                                }
                            }

                            textarea {
                                height: 227px;
                                padding: 15px 10px 0 14px;
                            }
                        }
                    }
                }
            
                .action { 
                    margin: 13px 0 0;
                    .submit { 
            
                        .btn { 
            
                        }
                    }
            
                    .agree-col { 
            
                        a { 
            
                        }
                    }
                }
            }
        }
    }
}

@import "../../media/tablet/includes/common/common_form_block";
@import "../../media/mobile/includes/common/common_form_block";
