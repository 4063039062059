.news-list-preview {
    display: inline-block;
    width: 100%;
    .news-list-preview-holder {
        display: inline-block;
        width: 100%;
        >.action {
            display: inline-block;
            width: 100%;
            .btn {
                display: inline-block;
                width: 100%;
                text-align: center;
                background-color: $bg-secondary;
                border-color: $bg-secondary;
                &:hover {
                    background-color: $brand-primary;
                    border-color: $brand-primary;
                    color: white;
                }
            }
        }
    }
}

.news-slider {
    display: inline-block;
    width: 100%;
    .prev, .next {

    }
    .news-slider-holder {

    }
}

.news-plain-list {
    width: calc(100% + 30px);
    margin-left: -15px;
    margin-bottom: -30px;
    .news-item { 
        display: block;
        float: left;
        width: 33.333%;
        box-sizing: border-box;
        padding: 0 15px;
        margin: 0 0 30px;
    }
}

.news-item.reverse {
    .news-item-holder { 
        a {
            .desc { 
                background-color: $bg-secondary;
            }
        }
    }
} 
.news-item { 
    display: inline-block;
    width: 100%;
	.news-item-holder { 
        display: inline-block;
        width: 100%;
		a { 
            display: inline-block;
            width: 100%;
            overflow: hidden;
            color: $gray-base;
            &:hover {
                .image { 
                    img {
                        transform: scale(1.1);
                        transition: all 0.3s cubic-bezier(0.45, 0, 0.55, 1);
                    }
                }
                .desc {
                    .action { 
                        color: $brand-primary;
                        transition: all 0.3s cubic-bezier(0.45, 0, 0.55, 1);
                        .icon { 
                            color: $brand-primary;
                            transform: translateX(5px);
                            transition: all 0.3s cubic-bezier(0.45, 0, 0.55, 1);
                        }
                    }
                }
            }
			.image { 
                display: inline-block;
                width: 100%;
                height: 300px;
                overflow: hidden;
				img { 
                    display: inline-block;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: top;
                    transition: all 0.3s cubic-bezier(0.45, 0, 0.55, 1);
				}
			}

			.desc { 
                display: inline-block;
                width: 100%;
                background-color: white;
                box-sizing: border-box;
                padding: 27px 30px 30px;
				.date { 
                    display: inline-block;
                    width: 100%;
                    margin: 0 0 13px;
                    font-size: 14px;
				}

				.name { 
                    width: 100%;
                    height: 50px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -moz-box;
                    -moz-box-orient: vertical;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    line-clamp: 2;
                    box-orient: vertical;
				}

				.action { 
                    display: inline-flex;
                    align-items: center;
                    width: 100%;
                    margin: 24px 0 0;
                    color: $gray;
                    transition: all 0.3s cubic-bezier(0.45, 0, 0.55, 1);
					.text { 
                        font-size: 14px;
					}

					.icon { 
                        width: 16px;
                        height: 10px;
                        margin-left: 10px;
                        color: $gray;
                        transition: all 0.3s cubic-bezier(0.45, 0, 0.55, 1);
						svg { 
                            width: 100%;
                            height: 100%;
						}
					}
				}
			}
		}
	}
}

@import "../../media/tablet/includes/common/news";
@import "../../media/mobile/includes/common/news";
