@media (max-width: $screen-sm) {
    // sm-view
    .login-link-block { 
        .login-link-block-holder { 
            .login-link { 
                .login-link-holder { 
                    a {
                        display: inline-block;
                        .icon {
                            width: 100%;
                            height: auto;
                            margin: 0 0 10px;
                            text-align: center;
                            .icon-holder {
                                width: 40px;
                                height: 40px;
                                display: inline-block;
                            }
                        }

                        .text {
                            .text-holder {
                                padding: 0;
                                text-align: center;
                                .heading { 
                                    margin: 0 0 5px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
