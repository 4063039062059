@media (max-width: $screen-sm) {
    // sm-view
    .division-detail-block {
        .division-detail-block-holder {
            >.back {
                a {
                    width: 100%;
                }
            }
            >.heading {
                margin: 0 0 14px;
            }
        } 
    }

    .division-detail-card {
        .division-detail-card-holder {
            padding: 0;
            background-color: transparent;
            .card-info { 
                .card-info-holder { 
                    display: inline-block;
                    padding: 20px;
                    .image {
                        width: 100%;
                        margin: 0 0 27px;
                        .image-holder {
                            text-align: left;
                            svg {
                                width: 123px;
                            }
                        }
                    }

                    .desc { 
                        width: 100%;
                        padding: 0;
                        .desc-holder { 
                            .heading { 
                                margin: 0 0 20px;
                                h3, .h3 {

                                }
                            }

                            .info-texts {
                                margin: 0 0 17px;
                                .info-text { 
                                    width: 100%;
                                    font-size: 16px;
                                    line-height: 22px;
                                    &:not(:last-of-type) {
                                        margin: 0 0 20px;
                                    }
                                    .info-text-holder { 

                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .info-contacts { 
        .info-contact { 
            width: 100%;
            &:not(:last-of-type) {
                margin: 0 0 20px;
            }
            .info-contact-holder { 

                a { 
                    font-size: 16px;
                    line-height: 22px;
                }
            }
        }
    }

    .division-personel {
        .division-personel-holder {
            padding: 0;
            .heading {

            }

            .division-personel-overview {
                .division-personel-overview-holder {
                    padding: 0;
                    background-color: transparent;
                    .image {

                    }

                    .personel-overview {
                        .personel-overview-holder {
                            .personel {
                                .personel-holder {
                                    .position {
                                    }

                                    .name {

                                    }
                                }
                            }
                        }
                    }
                }
            }

            .personel-detail-info {
                .personel-detail-info-holder  {
                    padding: 0;
                    background-color: transparent;
                    .personel-image {
                        width: 100%;
                        margin: 0 0 20px;
                    }

                    .personel-info {
                        width: 100%;
                        padding: 0;
                        .personel-info-holder {
                            
                            .position {
                            }

                            .name {

                            }

                            .info-contacts { 
                                
                            }

                            >.text {
                                
                            }
                        }
                    }
                }
            }
        }
    }

}

@media (max-width: $screen-xs) {
    // xs-view
}
