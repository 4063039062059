@media (max-width: $screen-sm) {
    // sm-view
    .common-form-block {
        .common-form-block-holder {
            .note {
                margin: 0 0 20px;
                font-size: 16px;
                line-height: 22px;
            }

            .form {
                form { 

                    .form-group { 
                        &.xs-float-anchor {
                            position: relative;
                            padding: 0 0 50px;
                        }
                        .form-col { 
                
                            .form-field { 
                                margin-bottom: 11px;
                                &.xs-float-to-bottom {
                                    position: absolute;
                                    bottom: 0;
                                    left: 0;;
                                }
                                .input { 
            
                                    .name { 
                
                                    }
                
                                    input { 
                
                                    }

                                    textarea {
                                        height: 100px;
                                        padding: 13px 10px 0 14px;
                                    }
                                }
                            }
                        }
                    }

                    .action {
                        margin: 7px 0 0;
                        .agree-col {
                            font-size: 14px;
                            line-height: 20px;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
