.plaque-links-block {
    display: inline-block;
    width: 100%;
    .plaque-links-block-holder {
        display: inline-block;
        width: 100%;
        .plaque-link.strong {
            .plaque-link-holder { 
                a {
                    font-size: 18px;
                    font-weight: bold;
                    .name {
                        margin: 0 0 12px;
                    }

                    .action { 
                        font-size: 16px;
                    }
                }
            }
        }
        .plaque-link { 
            display: inline-block;
            width: 100%;
            height: 100%;
            .plaque-link-holder { 
                display: inline-block;
                width: 100%;
                height: 100%;
                a { 
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    height: 100%;
                    background-color: $bg-secondary;
                    box-sizing: border-box;
                    padding: 27px 30px 30px;
                    font-size: 16px;
                    line-height: 140%;
                    color: $gray-base;
                    &:hover {
                        .action { 
                            color: $brand-primary;
                            transition: all 0.3s cubic-bezier(0.45, 0, 0.55, 1);
                            .icon { 
                                color: $brand-primary;
                                transform: translateX(5px);
                                transition: all 0.3s cubic-bezier(0.45, 0, 0.55, 1);
                            }
                        }
                    }
                    .name { 
                        display: inline-block;
                        width: 100%;
                        flex-grow: 1;
                        margin: 0 0 20px;
                        &.lines-2 {
                            min-height: 44px;
                        }
                    }
        
                    .action { 
                        display: flex;
                        align-items: center;
                        width: 100%;
                        color: $gray;
                        font-size: 14px;
                        transition: all 0.3s cubic-bezier(0.45, 0, 0.55, 1);
                        .text { 
                            float: left;
                        }
        
                        .icon { 
                            float: left;
                            width: 16px;
                            height: 10px;
                            margin-left: 10px;
                            color: $gray;
                            transition: all 0.3s cubic-bezier(0.45, 0, 0.55, 1);
                            svg { 
                                width: 100%;
                                height: 100%;
                            }
                        }
                    }
                }
            }
        }
    }
}

@import "../../media/tablet/includes/common/plaque_links";
@import "../../media/mobile/includes/common/plaque_links";
