.local-search-block { 
    display: inline-block;
    width: 100%;
	.local-search-block-holder { 
        display: inline-block;
        width: 100%;
        .from { 
            display: inline-block;
            width: 100%;
            form { 
                display: inline-block;
                width: 100%;
                .form-field { 
                    display: inline-block;
                    width: 100%;
                    margin: 0;
                    .input { 
                        display: inline-block;
                        width: 100%;
                        .name { 

                        }

                        input { 
                            height: 54px;
                            padding: 0 61px 0 20px;
                        }

                        .addon { 

                            .btn { 
                                width: 50px;
                                height: 50px;
                                line-height: 50px;
                                text-align: center;
                                padding: 0;
                                background-color: white;
                                border-color: white;
                                border-radius: 50%;
                                color: $brand-primary;
                                svg { 
                                    vertical-align: middle;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@import "../../media/tablet/includes/common/local_search";
@import "../../media/mobile/includes/common/local_search";
