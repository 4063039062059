@media (max-width: $screen-sm) {
    // sm-view
    .application-form {
        .application-form-holder {
            background-color: transparent;
            padding: 0;
            form {
                width: 100%;
                padding: 15px;

                .action {
                    .submit {
                        .btn {
                            font-size: 18px;
                        }
                    }
                }

                .checkbox-list {
                    margin: 0 0 5px;
                    .heading {

                    }

                    .checkbox-item {

                    }
                }

                .form-field {
                    label.checkcontainer {
                        .checkmark {
                            margin: 3px 0 0;
                        }

                        .name {
                            font-size: 16px;
                            line-height: 20px;
                        }
                    }

                    label.select {
                        .selectric-selectric-select {
                            .selectric {
                                height: 40px;
                                .label {
                                    line-height: 40px;
                                    font-size: 16px;
                                    margin: 0 5px 0 10px;
                                }
                            }
                        }
                    }

                    label.input {
                        &.captcha {
                            input {
                                width: 149px;
                                float: left;
                                height: 30px;
                                padding: 0 10px 0 5px;
                            }
    
                            .captcha-image {
                                float: left;
                                margin-left: 5px;
                                margin-top: 2px;
                                img {
                                    height: 25px;
                                    width: auto;
                                }
                            }
                        }

                        input, textarea {
                            height: 40px;
                            font-size: 16px;
                            line-height: 40px;
                            padding: 0 10px 0 5px;
                            @include placeholder {
                                font-size: 16px;
                                line-height: 40px;
                            }
                        }

                        textarea {
                            line-height: 22px;
                            height: 86px;
                            padding: 10px;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
