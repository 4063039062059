@media (max-width: $screen-lg) {
    // lg-md view
    .iconed-links-block { 
        .iconed-links-block-holder { 
            .cols {
                .col {
                    &.col-custom-70 {
                        width: 62%;
                    }
                    &.col-custom-30 {
                        width: 38%;
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-md) {
    // md-view
    .iconed-links-block { 
        .iconed-links-block-holder { 
            .cols {
                .col {
                    &.col-custom-70 {
                        width: 100%;
                    }
                    &.col-custom-30 {
                        width: 100%;
                    }
                    &.col-custom-50-minus-290 {
                        width: 33.333%;
                    }
                    &.col-custom-290 {
                        width: 33.333%;
                    }
                }
            }
            .link-item {
                .link-item-holder { 
                    .link.side-way-image {
                        flex-direction: column;
                        .image {
                            width: 100%;
                            height: auto;
                        }

                        >.text {
                            width: 100%;
                            padding: 0;
                        }
                    }
                    .link { 
                        padding: 16px 25px 16px;
                        .image { 
                            min-height: 222px;
                        }

                        >.text {
                            .text-holder { 
                                .heading { 

                                }

                                .desc { 
                                    font-size: 12px;
                                    margin: 13px 0 0;
                                }

                                .action {
                                    margin: 15px 0 12px;
                                }

                                .big-action {
                                    margin: 13px 0 0;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
