.division-detail-block {
    display: inline-block;
    width: 100%;
    .division-detail-block-holder {
        display: inline-block;
        width: 100%;
        >.back {
            display: inline-block;
            width: 100%;
            margin: 0 0 30px;
            a {
                float: left;
                background-color: $bg-secondary;
                border-color: $bg-secondary;
                padding: 9.5px 39px;
                &:hover {
                    background-color: $brand-primary;
                    border-color: $brand-primary;
                    color: white;
                }
                .icon {
                    float: left;
                    margin-right: 10px;
                    width: 16px;
                    height: 9px;
                    padding: 7px 0 0;
                    svg {
                        width: 100%;
                        height: auto;
                    }
                }
                .text {
                    float: left;
                }
            }
        }
        >.heading {
            display: inline-block;
            width: 100%;
            margin: 0 0 33px;
            .heading-holder {
                display: inline-block;
                width: 100%;
                h1, .h1 {

                }
            }
        }
        .division-detail {
            display: inline-block;
            width: 100%;
            .division-detail-holder {
                display: inline-block;
                width: 100%;
            }
        }
    }
}

.division-detail-card {
    display: inline-block;
    width: 100%;
    .division-detail-card-holder {
        display: inline-block;
        width: 100%;
        background-color: white;
        overflow: hidden;
        box-sizing: border-box;
        padding: 30px;
        .card-info { 
            display: inline-block;
            width: 100%;
            .card-info-holder { 
                display: flex;
                align-items: center;
                width: 100%;
                background-color: $bg-secondary;
                overflow: hidden;
                box-sizing: border-box;
                padding: 30px;
                .image { 
                    float: left;
                    width: 167px;
                    .image-holder { 
                        display: inline-block;
                        width: 100%;
                        svg { 
                            width: 100%;
                            height: auto;
                        }
                    }
                }
        
                .desc { 
                    float: left;
                    width: calc(100% - 167px);
                    box-sizing: border-box;
                    padding-left: 30px;
                    .desc-holder { 
                        display: inline-block;
                        width: 100%;
                        .heading { 
                            display: inline-block;
                            width: 100%;
                            margin: 0 0 23px;
                            h3, .h3 { 
        
                            }
                        }
        
                        .info-texts { 
                            display: inline-block;
                            width: 100%;
                            margin: 0 0 25px;
                            .info-text { 
                                float: left;
                                &:not(:last-of-type) {
                                    margin-right: 30px;
                                }
                                .info-text-holder { 
        
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.info-contacts { 
    display: inline-block;
    width: 100%;
    .info-contact { 
        float: left;
        &:not(:last-of-type) {
            margin-right: 30px;
        }
        .info-contact-holder { 

            a { 
                display: inline-flex;
                align-items: center;
                color: $gray-base;
                &:hover {
                    color: $brand-primary;
                }
                .icon { 
                    margin-right: 10px;
                    width: 16px;
                    height: 16px;
                    svg {
                        width: 100%;
                        height: 100%;
                    }
                }

                .text { 

                }
            }
        }
    }
}

.division-personel {
    display: inline-block;
    width: 100%;
    .division-personel-holder {
        display: inline-block;
        width: 100%;
        overflow: hidden;
        box-sizing: border-box;
        padding: 30px;
        background-color: white;
        .heading {
            display: inline-block;
            width: 100%;
            margin: 0 0 32px;
            h3, .h3 {

            }
        }
        .division-personel-overview {
            display: inline-block;
            width: 100%;
            .division-personel-overview-holder {
                display: inline-block;
                width: 100%;
                overflow: hidden;
                box-sizing: border-box;
                padding: 30px;
                background-color: $bg-secondary;
                .image {
                    display: inline-block;
                    width: 100%;
                    .image-holder {
                        display: inline-block;
                        width: 100%;
                        overflow: hidden;
                        img {
                            display: inline-block;
                            width: 100%;
                        }
                    }
                }

                .personel-overview {
                    display: inline-block;
                    width: 100%;
                    margin: 0 0 -4px;
                    .personel-overview-holder {
                        display: inline-block;
                        width: 100%;
                        .personel {
                            display: inline-block;
                            width: 100%;
                            margin: 0 0 4px;
                            .personel-holder {
                                display: inline-block;
                                width: 100%;
                                .position {
                                    display: inline-block;
                                    width: 100%;
                                    margin: 0 0 17px;
                                    font-weight: 700;
                                }

                                .name {
                                    display: inline-block;
                                    width: 100%;
                                    color: $gray;
                                    line-height: 22px;
                                }
                            }
                        }
                    }
                }
            }
        }

        .personel-detail-info {
            display: inline-block;
            width: 100%;
            .personel-detail-info-holder  {
                display: inline-block;
                width: 100%;
                background-color: $bg-secondary;
                box-sizing: border-box;
                padding: 30px;
                .personel-image {
                    float: left;
                    width: 255px;
                    .personel-image-holder {
                        display: inline-block;
                        width: 100%;
                        overflow: hidden;
                        img {
                            display: inline-block;
                            width: 100%;
                        }
                    }
                }

                .personel-info {
                    float: left;
                    width: calc(100% - 255px);
                    box-sizing: border-box;
                    padding-left: 30px;
                    .personel-info-holder {
                        display: inline-block;
                        width: 100%;    
                        .position {
                            display: inline-block;
                            width: 100%;
                            font-size: 14px;
                            height: 20px;
                            color: $gray;
                        }

                        .name {
                            display: inline-block;
                            width: 100%;
                        }

                        .info-contacts { 
                            margin: 21px 0 0;
                            .info-contact { 
                        
                                .info-contact-holder { 
                        
                                    a { 
                        
                                        .icon { 
                                            margin-right: 5px;
                                            svg { 
                        
                                            }
                                        }
                        
                                        .text { 
                        
                                        }
                                    }
                                }
                            }
                        }

                        >.text {
                            display: inline-block;
                            width: 100%;
                            margin: 29px 0 0;
                            .text-holder {
                                display: inline-block;
                                width: 100%;
                                color: $gray;
                                p {
                                    &:not(:last-of-type) {
                                        margin: 0 0 10px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@import "../../media/tablet/includes/common/division_detail";
@import "../../media/mobile/includes/common/division_detail";
