@media (max-width: $screen-lg) {
    // lg-md view
}

@media (max-width: $screen-md) {
    // md-view
    .links-buttons-block {
        .links-buttons-block-holder {
            padding: 25px;
            .heading {

            }

            ul {
                margin: 0 -4px -12px;
                li {
                    padding: 0 4px 12px;
                }
            }
        }
    }
}
