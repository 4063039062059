@media (max-width: $screen-sm) {
    // sm-view
    .links-buttons-block {
        .links-buttons-block-holder {
            padding: 0 0 15px;
            &.no-xs-padding {
                padding: 0;
            }
            &.bg-secondary {
                background-color: white;
                ul {
                    li {
                        a {
                            background-color: $bg-secondary;
                            border-color: $bg-secondary;
                        }
                    }
                }
            }
            .heading {
                margin: 0 0 15px;
            }
            ul {
                margin: 0 0 -5px;
                li {
                    width: 100%;
                    padding: 0 0 5px;
                    a {
                        width: 100%;
                        padding: 9px 14px;
                        font-size: 14px;
                        line-height: 20px;
                        border-radius: 15px;
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
