@media (max-width: $screen-sm) {
    // sm-view
    .common-single-text-block.with-side-image {
        .common-single-text-block-holder { 
            .image {
                width: 100%;
                margin: 0 0 10px;
            }

            .text { 
                width: 100%;
                padding: 0;
            }
        }
    }
    .common-single-text-block { 
        .common-single-text-block-holder { 
            padding: 0;
            &.bg-secondary {
                background-color: white;
            }
            .inline-link-list {

            }
            .text { 
                .text-holder { 
                    h3, .h3 {
                        margin: 0 0 15px;
                    }

                    .external-link { 
                        margin: 17px 0 0;
                        a {
                            font-size: 14px;
                        }
                    }

                    .action {
                        margin: 20px 0 0;
                        .btn {
                            width: 100%;
                            font-size: 16px;
                            line-height: 22px;
                            padding: 8px 10px;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
