@media (max-width: $screen-sm) {
    // sm-view
    .news-detail {
        overflow: hidden;
        margin: 0 0 29px;
        .news-detail-holder {
            .heading {
                margin: 0 0 20px;
            }

            .detail-image {
                margin: 0 0 17px;
                .detail-image-holder {

                }
            }
            .text {
                .text-holder {
                    p {
                        font-size: 16px;
                        line-height: 22px;
                        &:not(:last-of-type) {
                            margin: 0 0 15px;
                        }
                    }
                }
            }

            .images-gallery {
                margin: 20px 0 0;
            }

            .add-info {
                margin: 14px 0 0;
                font-size: 16px;
                line-height: 22px;
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
