@media (max-width: $screen-lg) {
    // lg-md view
}

@media (max-width: $screen-md) {
    // md-view
    .common-single-text-block.with-side-image {
        .common-single-text-block-holder { 
            .image {
                width: 195px;
            }

            .text { 
                width: calc(100% - 195px);
            }
        }
    }
    .common-single-text-block { 
        .common-single-text-block-holder { 
            padding: 25px;
            .inline-link-list {

            }

            .text {
                .text-holder { 
                    h3, .h3 {
                        margin: 0 0 20px;
                    }

                    ul, ol {
                        li {
                            &:not(:last-of-type) {
                                margin: 0;
                            }
                        }
                    }

                    .external-link { 
                        margin: 25px 0 0;
                        a {
                            font-size: 16px;
                        }
                    }

                    .action {
                        margin: 30px 0 0;
                        .btn {
                            min-width: 205px;
                        }
                    }
                }
            }
        }
    }
}
