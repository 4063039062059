.banner {
    display: inline-block;
    width: 100%;
    .banner-holder {
        display: inline-block;
        width: 100%;
        overflow: hidden;
        a {
            display: inline-block;
            width: 100%;
            img {
                display: inline-block;
                width: 100%;
                height: auto;
            }
        }
    }
}

.banner-slider {
    .nav {
        ul.slick-dots {
            bottom: 40px;
        }
    }
    .banner-slider-holder {

    }
}

@import "../../media/tablet/includes/common/banners";
@import "../../media/mobile/includes/common/banners";
