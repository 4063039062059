.news-detail {
    display: inline-block;
    width: 100%;
    margin: 0 0 64px;
    .news-detail-holder {
        display: inline-block;
        width: 100%;
        .heading {
            display: inline-block;
            width: 100%;
            margin: 0 0 30px;
            h1, .h1 {

            }
        }
        .detail-image {
            display: inline-block;
            width: 100%;
            margin: 0 0 25px;
            .detail-image-holder {
                display: inline-block;
                width: 100%;
                overflow: hidden;
                img {
                    display: inline-block;
                    width: 100%;
                    object-fit: cover;
                }
            }
        }
        .text {
            display: inline-block;
            width: 100%;
            .text-holder {
                display: inline-block;
                width: 100%;
                p {
                    
                }
            }
        }

        .images-gallery {
            margin: 30px 0 0;
        }

        .add-info {
            margin: 33px 0 0;
            color: $gray
        }
    }
}

@import "../../media/tablet/includes/common/news_detail";
@import "../../media/mobile/includes/common/news_detail";
