@media (max-width: $screen-lg) {
    // lg-md view
}

@media (max-width: $screen-md) {
    // md-view
    .empty-search {
        .empty-search-holder {
            padding: 70px 0;
        }
    }
}
