@media (max-width: $screen-lg) {
    // lg-md view
}

@media (max-width: $screen-md) {
    // md-view
    .division-history-block {
        .division-history-block-holder { 
            padding: 20px;
            >.text {
                h3, .h3 { 
                    margin: 0 0 20px;
                }

                p { 
                    &:not(:last-of-type) {
                        margin: 0 0 20px;
                    }
                }
            }

            .historical-reference { 
                .historical-reference-holder { 
                    padding: 25px;
                    .image { 
                        max-width: 225px;
                        &.left {
                            float: left;
                            padding: 0 15px 15px 0;
                        }
                        &.right {
                            float: right;
                            padding: 0 0 15px 15px;
                        }
                        img {
                            width: 100%;
                        }

                        .desc { 
                            margin: 20px 0 0;
                        }
                    }

                    p {
                        &:not(:last-of-type) {
                            margin: 0 0 10px;
                        }
                    }
                }
            }
        }
    }
}
