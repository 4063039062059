@media (max-width: $screen-lg) {
    // lg-md view
    .not-found { 
        .not-found-holder { 
            width: 78%;
        }
    }
}

@media (max-width: $screen-md) {
    // md-view
    .not-found {
        .not-found-holder { 
            width: 100%;
            padding: 49px 0 62px;
            >.text { 
                width: calc(100% - 408px);
                .text-holder {
                    padding: 0 8% 0 0;
                    .link {
                        a {
                            font-size: 12px;
                            line-height: 17px;
                        }
                    }
                }
            }

            .image { 
                width: 408px;
                margin: 34px 0 0 0;
            }
        }
    }
}
