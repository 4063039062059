@media (max-width: $screen-lg) {
    // lg-md view
}

@media (max-width: $screen-md) {
    // md-view
    .login-link-block { 
        .login-link-block-holder { 
            .login-link { 
                .login-link-holder { 
                    a {
                        padding: 20px 10px;
                        .icon { 
                            width: 60px;
                            height: 60px;
                            margin-right: 20px;
                        }
                    }
                }
            }
        }
    }
}
