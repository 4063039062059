.gallery-block {
    display: inline-block;
    width: 100%;
    .gallery-block-holder {
        display: inline-block;
        width: 100%;
        box-sizing: border-box;
        padding: 30px;
        background-color: $bg-secondary;
        overflow: hidden;
        .heading {
            display: flex;
            align-items: start;
            justify-content: space-between;
            width: 100%;
            margin: 0 0 30px;
            .h3, h3 {
                max-width: 90%;
            }

            .date{
                color: $gray;
                font-size: 14px;
                line-height: 20px;
                padding: 5px 0 0;
            }
        }
    }
}

.images-gallery.long {
    .images-gallery-holder { 
        .images-gallery-list { 
            .images-gallery-list-holder { 
                .gallery-image { 
                    .gallery-image-holder { 
                        a {
                            .inner-image {
                                margin-top: 105%;
                            }
                        }
                    }
                }
            }
        }
    }
} 
.images-gallery { 
    display: inline-block;
    width: 100%;
	.images-gallery-holder { 
        display: inline-block;
        width: 100%;
		.images-gallery-list.row-4 {
            .images-gallery-list-holder { 
                .gallery-image { 
                    width: 25%;
                }
            }
        } 
		.images-gallery-list { 
            display: inline-block;
            width: 100%;
			.images-gallery-list-holder { 
                display: inline-block;
                width: calc(100% + 30px);
                margin-left: -15px;
                margin-bottom: -27px;
				.gallery-image { 
                    float: left;
                    width: 16.666%;
                    box-sizing: border-box;
                    padding: 0 15px;
                    margin: 0 0 27px;
					.gallery-image-holder { 
                        display: inline-block;
                        width: 100%;
						a { 
                            display: inline-block;
                            width: 100%;
                            overflow: hidden;
                            position: relative;
                            &:hover {
                                .inner-image {
                                    img {
                                        transform: scale(1.1);
                                        transition: all 0.3s cubic-bezier(0.45, 0, 0.55, 1);
                                    }
                                }
                            }
                            .inner-image {
                                display: inline-block;
                                width: 100%;
                                margin-top: 100%;
                                img { 
                                    position: absolute;
                                    top: 0;
                                    bottom: 0;
                                    left: 0;
                                    right: 0;
                                    width: 100%;
                                    height: 100%;
                                    object-fit: cover;
                                    transition: all 0.3s cubic-bezier(0.45, 0, 0.55, 1);
                                }
                            }
							
						}
					}
				}
			}
		}

		>.action {
            display: inline-block;
            width: 100%;
            margin: 22px 0 0;
            &.reverse {
                .btn {
                    background-color: white;
                    border-color: white;
                    &:hover {
                        background-color: $brand-primary;
                        border-color: $brand-primary;
                        color: white;
                    }
                }
            }
            .btn {
                display: inline-block;
                width: 100%;
                text-align: center;
                background-color: $bg-secondary;
                border-color: $bg-secondary;
                &:hover {
                    background-color: $brand-primary;
                    border-color: $brand-primary;
                    color: white;
                }
            }
        }
	}
}

@import "../../media/tablet/includes/common/images_gallery";
@import "../../media/mobile/includes/common/images_gallery";
