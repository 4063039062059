@media (max-width: $screen-sm) {
    // sm-view
    .local-search-block { 
        .local-search-block-holder { 
            .from { 
                form {
                    .form-field { 
                        .input { 
                            input { 
                                height: 40px;
                                padding: 0 41px 0 15px;
                            }

                            .addon { 

                                .btn { 
                                    width: 30px;
                                    height: 30px;
                                    line-height: 30px;
                                    svg {
                                        width: 10px;
                                        height: 10px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
