@media (max-width: $screen-lg) {
    // lg-md view
}

@media (max-width: $screen-md) {
    // md-view
    .common-form-block {
        .common-form-block-holder {
            max-width: none;
            width: 100%;
            .note {
                line-height: 130%;
            }

            .form {
                form { 

                    .form-group { 
                
                        .form-col { 
                
                            .form-field { 
                                margin-bottom: 16px;
                                .input { 
            
                                    .name { 
                
                                    }
                
                                    input { 
                
                                    }

                                    textarea {
                                        height: 208px;
                                    }
                                }
                               
                            }
                        }
                    }

                    .action { 
                        .submit { 
            
                            .btn { 
                
                            }
                        }
                
                        .agree-col { 
                            font-size: 12px;
                        }
                    }
                }
            }
        }
    }
}
