@media (max-width: $screen-sm) {
    // sm-view
    .breadcrumbs {
        .breadcrumbs-holder {
            ul.breadcrumbs-list {
                li {
                    font-size: 14px;
                    line-height: 20px;
                    margin-right: 3px;
                }
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
