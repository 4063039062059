.iconed-links-block { 
    display: inline-block;
    width: 100%;
	.iconed-links-block-holder { 
        display: inline-block;
        width: 100%;
        .cols {
            .col {
                &.col-custom-70 {
                    width: 70.5%;
                }
                &.col-custom-30 {
                    width: 29.5%;
                }
                &.col-custom-50-minus-290 {
                    width: calc(50% - 160px);
                }
                &.col-custom-290 {
                    width: 320px;
                }
            }
        }
        .link-item.reverse {
            .link-item-holder { 
                .link {
                    background-color: white;
                }
            }
        }
        .link-item { 
            display: inline-block;
            width: 100%;
            height: 100%;
            .link-item-holder { 
                display: inline-block;
                width: 100%;
                height: 100%;
                .link.side-way-image {
                    flex-direction: row;
                    align-items: center;
                    .image { 
                        width: 115px;
                        height: 115px;
                        flex-grow: 0;
                        .image-holder { 
                            width: 100%;
                            height: 100%;
                            svg {
                                width: 100%;
                                height: auto;
                            }
                        }
                    }

                    >.text { 
                        width: calc(100% - 150px);
                        box-sizing: border-box;
                        padding-left: 30px;
                    }
                }
                a.link:hover {
                    >.text { 
                        .text-holder { 
                            .heading { 
                                color: $brand-primary;
                                transition: all 0.3s cubic-bezier(0.45, 0, 0.55, 1);
                            }
                            .action { 
                                color: $brand-primary;
                                transition: all 0.3s cubic-bezier(0.45, 0, 0.55, 1);
                                .icon { 
                                    color: $brand-primary;
                                    transform: translateX(5px);
                                    transition: all 0.3s cubic-bezier(0.45, 0, 0.55, 1);
                                }
                            }
                        }
                    }
                }
                .link { 
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    height: 100%;
                    overflow: hidden;
                    background-color: $bg-secondary;
                    box-sizing: border-box;
                    padding: 28px 30px 30px;
                    color: $gray-base;
                    
                    .image { 
                        display: inline-block;
                        width: 100%;
                        flex-grow: 1;
                        .image-holder { 
                            display: inline-block;
                            width: 100%;
                            svg { 
                                display: inline-block;
                                width: 100%;
                            }
                        }
                    }

                    >.text { 
                        display: inline-block;
                        width: 100%;
                        .text-holder { 
                            display: inline-block;
                            width: 100%;
                            .heading { 
                                display: inline-block;
                                width: 100%;
                                color: $gray-base;
                                transition: all 0.3s cubic-bezier(0.45, 0, 0.55, 1);
                            }

                            .desc { 
                                display: inline-block;
                                width: 100%;
                                color: $gray;
                                font-size: 14px;
                                line-height: 140%;
                                margin: 11px 0 0;
                                color: $gray;
                                &.lines-2 {
                                    height: 40px;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    display: -moz-box;
                                    -moz-box-orient: vertical;
                                    display: -webkit-box;
                                    -webkit-line-clamp: 2;
                                    -webkit-box-orient: vertical;
                                    line-clamp: 2;
                                    box-orient: vertical;
                                }
                            }

                            .big-action { 
                                display: inline-block;
                                width: 100%;
                                margin: 29px 0 0;
                                .btn { 
                                    display: inline-block;
                                    width: 100%;
                                    text-align: center;
                                    padding: 9.5px 15px;
                                }
                            }

                            .action { 
                                display: inline-flex;
                                align-items: center;
                                width: 100%;
                                color: $gray;
                                font-size: 14px;
                                transition: all 0.3s cubic-bezier(0.45, 0, 0.55, 1);
                                margin: 20px 0 0;
                                .text { 
                                    float: left;
                                }
                
                                .icon { 
                                    float: left;
                                    width: 16px;
                                    height: 10px;
                                    margin-left: 10px;
                                    color: $gray;
                                    transition: all 0.3s cubic-bezier(0.45, 0, 0.55, 1);
                                    svg { 
                                        width: 100%;
                                        height: 100%;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

    }
}

@import "../../media/tablet/includes/common/iconed_links";
@import "../../media/mobile/includes/common/iconed_links";
