.common-section-header { 
    display: inline-block;
    width: 100%;
    margin: 0 0 30px;
	.common-section-header-holder { 
        display: flex;
        align-items: center;
        justify-content: space-between;
		.text { 

		}

		.action { 

			a { 
                display: flex;
                align-items: center;
                color: $gray-base;
                &:hover {
                    color: $brand-primary;
                    transition: all 0.3s cubic-bezier(0.45, 0, 0.55, 1);
                    .icon { 
                        color: $brand-primary;
                        transform: translateX(5px);
                        transition: all 0.3s cubic-bezier(0.45, 0, 0.55, 1);
                    }
                }
				.text { 

				}

				.icon { 
                    width: 16px;
                    height: 10px;
                    margin-left: 10px;
                    color: $gray-base;
					svg { 
                        width: 100%;
                        height: 100%;
					}
				}
			}
		}
	}
}

@import "../../media/tablet/includes/common/common_section_heading";
@import "../../media/mobile/includes/common/common_section_heading";
