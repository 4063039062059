@media (max-width: $screen-sm) {
    // sm-view
    .not-found {
        .not-found-holder { 
            display: flex;
            flex-direction: column-reverse;
            padding: 10px 0 30px;
            >.text { 
                width: 100%;
                .text-holder {
                    padding: 0 1% 0 0;
                    h1, .h1 {
                        margin: 0 0 18px;
                    }

                    p {
                        &:not(:last-of-type) {
                            margin: 0 0 13px;
                        }
                    }

                    .link {
                        margin: 23px 0 0;
                        a {
                            .text {

                            }

                            .icon {

                            }
                        }
                    }
                }
            }

            .image { 
                width: 100%;
                margin: 0 0 30px;
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
