@media (max-width: $screen-lg) {
    // lg-md view
}

@media (max-width: $screen-md) {
    // md-view
    .news-detail {
        margin: 0 0 43px;
        .news-detail-holder {
            .heading {

            }

            .detail-image {

            }

            .text {
                .text-holder {
                    p {
                        line-height: 18px;
                    }
                }
            }
        }
    }
}
