@media (max-width: $screen-lg) {
    // lg-md view
}

@media (max-width: $screen-md) {
    // md-view
    .plaque-links-block {
        .plaque-links-block-holder {
            .plaque-link.strong {
                .plaque-link-holder { 
                    a {
                        font-size: 16px;
                        .action { 
                            font-size: 14px;
                        }
                    }
                }
            }
            .plaque-link { 
                .plaque-link-holder { 
                    a {
                        font-size: 14px;
                        line-height: 130%;
                        padding: 17px 25px 20px;
                        .name { 
                            margin: 0 0 15px;
                            &.lines-2 {
                                min-height: 39px;
                            }
                        }

                        .action { 
                            font-size: 12px;
                        }
                    }
                }
            }
        }
    }
}
