.division-history-block { 
    display: inline-block;
    width: 100%;
	.division-history-block-holder { 
        display: inline-block;
        width: 100%;
        overflow: hidden;
        background-color: white;
        box-sizing: border-box;
        padding: 30px;
        >.text {
            display: inline-block;
            width: 100%;
            h3, .h3 { 
                margin: 0 0 30px;
            }

            p { 
                &:not(:last-of-type) {
                    margin: 0 0 30px;
                }
            }
        }

		.historical-reference { 
            display: inline-block;
            width: 100%;
			.historical-reference-holder { 
                display: inline-block;
                width: 100%;
                overflow: hidden;
                background-color: $bg-secondary;
                box-sizing: border-box;
                padding: 30px;
				.image { 
                    &.left {
                        float: left;
                        padding: 0 30px 30px 0;
                    }
                    &.right {
                        float: right;
                        padding: 0 0 30px 30px;
                    }
					img { 

					}

					.desc { 
                        margin: 34px 0 0;
                        font-weight: 700;
					}
				}

				p { 
                    color: $gray;
                    &:not(:last-of-type) {
                        margin: 0 0 11px;
                    }
				}
			}
		}
	}
}

@import "../../media/tablet/includes/common/division_history";
@import "../../media/mobile/includes/common/division_history";
