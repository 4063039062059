.breadcrumbs {
  display: inline-block;
  width: 100%;
  margin-top: 0;
  margin-bottom: 30px;
}
.breadcrumbs .breadcrumbs-holder {
  display: inline-block;
  width: 100%;
  box-sizing: border-box;
}
.breadcrumbs .breadcrumbs-holder ul.breadcrumbs-list {
  display: inline-block;
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
}
.breadcrumbs .breadcrumbs-holder ul.breadcrumbs-list li {
  display: inline;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  margin-right: 4px;
  color: #1D1D1B;
  padding: 0;
}
.breadcrumbs .breadcrumbs-holder ul.breadcrumbs-list li a {
  color: #D7D7D7;
  text-decoration: none;
  transition: all 300ms;
}
.breadcrumbs .breadcrumbs-holder ul.breadcrumbs-list li a:hover {
  color: #056256;
  transition: all 300ms;
}
.breadcrumbs .breadcrumbs-holder ul.breadcrumbs-list li.sep {
  color: #D7D7D7;
}
.breadcrumbs .breadcrumbs-holder ul.breadcrumbs-list li.sep svg {
  width: 8px;
  height: 4px;
  vertical-align: middle;
}

@media (max-width: 1279px) {
  .breadcrumbs {
    margin-bottom: 20px;
  }
}
@media (max-width: 767px) {
  .breadcrumbs .breadcrumbs-holder ul.breadcrumbs-list li {
    font-size: 14px;
    line-height: 20px;
    margin-right: 3px;
  }
}
.not-found {
  display: inline-block;
  width: 100%;
  min-height: 464px;
}
.not-found .not-found-holder {
  width: 69.666%;
  margin: 0 auto;
  padding: 72px 0 72px;
}
.not-found .not-found-holder > .text {
  float: left;
  width: calc(100% - 528px);
}
.not-found .not-found-holder > .text .text-holder {
  display: inline-block;
  width: 100%;
  box-sizing: border-box;
  padding: 0 21% 0 0;
}
.not-found .not-found-holder > .text .text-holder h1, .not-found .not-found-holder > .text .text-holder .h1 {
  margin: 0 0 17px;
}
.not-found .not-found-holder > .text .text-holder p {
  color: #666666;
}
.not-found .not-found-holder > .text .text-holder .link {
  display: inline-block;
  width: 100%;
  margin: 21px 0 0;
}
.not-found .not-found-holder > .text .text-holder .link a {
  display: inline-flex;
  align-items: center;
  font-size: 14px;
  line-height: 20px;
}
.not-found .not-found-holder > .text .text-holder .link a:hover {
  color: #0ac3ab;
  transition: all 0.3s cubic-bezier(0.45, 0, 0.55, 1);
}
.not-found .not-found-holder > .text .text-holder .link a:hover .icon {
  color: #0ac3ab;
  transform: translateX(5px);
  transition: all 0.3s cubic-bezier(0.45, 0, 0.55, 1);
}
.not-found .not-found-holder > .text .text-holder .link a .icon {
  margin-left: 10px;
  width: 16px;
  height: 10px;
  color: #056256;
  transition: all 0.3s cubic-bezier(0.45, 0, 0.55, 1);
}
.not-found .not-found-holder > .text .text-holder .link a .icon svg {
  width: 100%;
  height: auto;
}
.not-found .not-found-holder .image {
  float: left;
  width: 528px;
  margin: 29px 0 0 0;
}
.not-found .not-found-holder .image .image-holder {
  display: inline-block;
  width: 100%;
}
.not-found .not-found-holder .image .image-holder svg {
  width: 100%;
  height: auto;
}
@media (max-width: 1470px) {
  .not-found .not-found-holder {
    width: 78%;
  }
}
@media (max-width: 1279px) {
  .not-found .not-found-holder {
    width: 100%;
    padding: 49px 0 62px;
  }
  .not-found .not-found-holder > .text {
    width: calc(100% - 408px);
  }
  .not-found .not-found-holder > .text .text-holder {
    padding: 0 8% 0 0;
  }
  .not-found .not-found-holder > .text .text-holder .link a {
    font-size: 12px;
    line-height: 17px;
  }
  .not-found .not-found-holder .image {
    width: 408px;
    margin: 34px 0 0 0;
  }
}
@media (max-width: 767px) {
  .not-found .not-found-holder {
    display: flex;
    flex-direction: column-reverse;
    padding: 10px 0 30px;
  }
  .not-found .not-found-holder > .text {
    width: 100%;
  }
  .not-found .not-found-holder > .text .text-holder {
    padding: 0 1% 0 0;
  }
  .not-found .not-found-holder > .text .text-holder h1, .not-found .not-found-holder > .text .text-holder .h1 {
    margin: 0 0 18px;
  }
  .not-found .not-found-holder > .text .text-holder p:not(:last-of-type) {
    margin: 0 0 13px;
  }
  .not-found .not-found-holder > .text .text-holder .link {
    margin: 23px 0 0;
  }
  .not-found .not-found-holder .image {
    width: 100%;
    margin: 0 0 30px;
  }
}
.application-form {
  display: inline-block;
  width: 100%;
}
.application-form .application-form-holder {
  display: inline-block;
  width: 100%;
  background-color: white;
  box-sizing: border-box;
  overflow: visible;
  padding: 80px 40px;
}
.application-form .application-form-holder form {
  display: block;
  margin: 0 auto;
  width: 52.5%;
  background-color: #e4e5e9;
  border-radius: 15px;
  overflow: visible;
  box-sizing: border-box;
  padding: 35px 47px 35px;
}
.application-form .application-form-holder form .action .submit {
  display: block;
  margin: 0 auto;
  width: 235px;
  float: none;
}
.application-form .application-form-holder form .action .submit .btn {
  border-radius: 5px;
  font-size: 21px;
  color: white;
  background-color: #777777;
  border-color: #777777;
}
.application-form .application-form-holder form .action .submit .btn:hover {
  background-color: #056256;
  border-color: #056256;
}
.application-form .application-form-holder form .checkbox-list {
  display: inline-block;
  width: 100%;
  margin: 0 0 9.5px;
}
.application-form .application-form-holder form .checkbox-list .heading {
  display: inline-block;
  width: 100%;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: -0.25px;
  margin: 0 0 3px;
}
.application-form .application-form-holder form .checkbox-list .checkbox-item {
  display: inline-block;
  width: 100%;
}
.application-form .application-form-holder form .checkbox-list .checkbox-item .form-field {
  margin: 0;
}
.application-form .application-form-holder form .form-field {
  margin: 0 0 9.5px;
}
.application-form .application-form-holder form .form-field label .name {
  font-size: 14px;
  line-height: 22px;
  letter-spacing: -0.25px;
  margin: 0 0 3px;
}
.application-form .application-form-holder form .form-field label input[type=file] {
  margin: 5px 0 7px;
}
.application-form .application-form-holder form .form-field label.checkcontainer .checkmark {
  width: 15px;
  height: 15px;
  margin: 4px 0 0;
  border-radius: 5px;
}
.application-form .application-form-holder form .form-field label.checkcontainer .name {
  font-size: 18px;
  line-height: 22px;
  margin: 0 0 0 10px;
}
.application-form .application-form-holder form .form-field label.select .selectric-selectric-select .selectric {
  border-color: #BABABA;
  height: 50px;
  border-radius: 5px;
}
.application-form .application-form-holder form .form-field label.select .selectric-selectric-select .selectric .label {
  line-height: 50px;
  font-size: 18px;
  margin: 0 45px 0 15px;
}
.application-form .application-form-holder form .form-field label.input.captcha input {
  width: 249px;
  float: left;
  height: 35px;
  padding: 0 15px 0 7px;
}
.application-form .application-form-holder form .form-field label.input.captcha .captcha-image {
  float: left;
  margin-left: 6px;
  margin-top: 3px;
}
.application-form .application-form-holder form .form-field label.input.captcha .captcha-image img {
  height: 30px;
  width: auto;
}
.application-form .application-form-holder form .form-field label.input input, .application-form .application-form-holder form .form-field label.input textarea {
  border-radius: 5px;
  color: #1D1D1B;
  border-color: #BABABA;
  height: 50px;
  padding: 0 15px 0 15px;
  font-size: 18px;
  line-height: 50px;
}
.application-form .application-form-holder form .form-field label.input input:focus, .application-form .application-form-holder form .form-field label.input textarea:focus {
  border-color: #056256;
}
.application-form .application-form-holder form .form-field label.input input::-webkit-input-placeholder, .application-form .application-form-holder form .form-field label.input textarea::-webkit-input-placeholder {
  color: #A5A5A5;
  font-size: 18px;
  line-height: 50px;
}

.application-form .application-form-holder form .form-field label.input input:-moz-placeholder, .application-form .application-form-holder form .form-field label.input textarea:-moz-placeholder {
  color: #A5A5A5;
  font-size: 18px;
  line-height: 50px;
}

.application-form .application-form-holder form .form-field label.input input::-moz-placeholder, .application-form .application-form-holder form .form-field label.input textarea::-moz-placeholder {
  color: #A5A5A5;
  font-size: 18px;
  line-height: 50px;
}

.application-form .application-form-holder form .form-field label.input input:-ms-input-placeholder, .application-form .application-form-holder form .form-field label.input textarea:-ms-input-placeholder {
  color: #A5A5A5;
  font-size: 18px;
  line-height: 50px;
}

.application-form .application-form-holder form .form-field label.input textarea {
  line-height: 27px;
  padding: 15px;
  height: 96px;
}

@media (max-width: 1470px) {
  .application-form .application-form-holder form {
    width: 65.3%;
  }
}
@media (max-width: 1279px) {
  .application-form .application-form-holder {
    padding: 40px 20px;
  }
  .application-form .application-form-holder form {
    width: 100%;
    padding: 25px 30px;
  }
}
@media (max-width: 767px) {
  .application-form .application-form-holder {
    background-color: transparent;
    padding: 0;
  }
  .application-form .application-form-holder form {
    width: 100%;
    padding: 15px;
  }
  .application-form .application-form-holder form .action .submit .btn {
    font-size: 18px;
  }
  .application-form .application-form-holder form .checkbox-list {
    margin: 0 0 5px;
  }
  .application-form .application-form-holder form .form-field label.checkcontainer .checkmark {
    margin: 3px 0 0;
  }
  .application-form .application-form-holder form .form-field label.checkcontainer .name {
    font-size: 16px;
    line-height: 20px;
  }
  .application-form .application-form-holder form .form-field label.select .selectric-selectric-select .selectric {
    height: 40px;
  }
  .application-form .application-form-holder form .form-field label.select .selectric-selectric-select .selectric .label {
    line-height: 40px;
    font-size: 16px;
    margin: 0 5px 0 10px;
  }
  .application-form .application-form-holder form .form-field label.input.captcha input {
    width: 149px;
    float: left;
    height: 30px;
    padding: 0 10px 0 5px;
  }
  .application-form .application-form-holder form .form-field label.input.captcha .captcha-image {
    float: left;
    margin-left: 5px;
    margin-top: 2px;
  }
  .application-form .application-form-holder form .form-field label.input.captcha .captcha-image img {
    height: 25px;
    width: auto;
  }
  .application-form .application-form-holder form .form-field label.input input, .application-form .application-form-holder form .form-field label.input textarea {
    height: 40px;
    font-size: 16px;
    line-height: 40px;
    padding: 0 10px 0 5px;
  }
  .application-form .application-form-holder form .form-field label.input input::-webkit-input-placeholder, .application-form .application-form-holder form .form-field label.input textarea::-webkit-input-placeholder {
    font-size: 16px;
    line-height: 40px;
  }
  .application-form .application-form-holder form .form-field label.input input:-moz-placeholder, .application-form .application-form-holder form .form-field label.input textarea:-moz-placeholder {
    font-size: 16px;
    line-height: 40px;
  }
  .application-form .application-form-holder form .form-field label.input input::-moz-placeholder, .application-form .application-form-holder form .form-field label.input textarea::-moz-placeholder {
    font-size: 16px;
    line-height: 40px;
  }
  .application-form .application-form-holder form .form-field label.input input:-ms-input-placeholder, .application-form .application-form-holder form .form-field label.input textarea:-ms-input-placeholder {
    font-size: 16px;
    line-height: 40px;
  }
  .application-form .application-form-holder form .form-field label.input textarea {
    line-height: 22px;
    height: 86px;
    padding: 10px;
  }
}
.banner {
  display: inline-block;
  width: 100%;
}
.banner .banner-holder {
  display: inline-block;
  width: 100%;
  overflow: hidden;
}
.banner .banner-holder a {
  display: inline-block;
  width: 100%;
}
.banner .banner-holder a img {
  display: inline-block;
  width: 100%;
  height: auto;
}

.banner-slider .nav ul.slick-dots {
  bottom: 40px;
}
@media (max-width: 1279px) {
  .banner-slider .nav ul.slick-dots {
    bottom: 5px;
  }
}
@media (max-width: 767px) {
  .banner-slider .nav ul.slick-dots {
    bottom: 0;
  }
}
.bulletin-section {
  display: inline-block;
  width: 100%;
}
.bulletin-section .bulletin-section-holder {
  display: inline-block;
  width: 100%;
}

.bulletin-tabs-block {
  display: inline-block;
  width: 100%;
}
.bulletin-tabs-block .bulletin-tabs-block-holder {
  display: inline-block;
  width: 100%;
  background-color: #F8F8F8;
  box-sizing: border-box;
  padding: 26px 30px 30px;
}
.bulletin-tabs-block .bulletin-tabs-block-holder .bulletin-tabs-controlls {
  display: inline-block;
  width: 100%;
  margin: 0 0 10px;
}
.bulletin-tabs-block .bulletin-tabs-block-holder .bulletin-tabs-controlls .bulletin-tabs-controlls-holder {
  display: flex;
  flex-wrap: wrap;
  width: calc(100% + 10px);
  margin: 0 -5px;
}
.bulletin-tabs-block .bulletin-tabs-block-holder .bulletin-tabs-controlls .bulletin-tabs-controlls-holder .controller-link {
  float: left;
  margin: 0 4px 10px;
}
.bulletin-tabs-block .bulletin-tabs-block-holder .bulletin-tabs-controlls .bulletin-tabs-controlls-holder .controller-link a {
  font-size: 16px;
  line-height: 140%;
  font-weight: 400;
  padding: 11.5px 20px;
}
.bulletin-tabs-block .bulletin-tabs-block-holder .bulletin-tabs-controlls .bulletin-tabs-controlls-holder .controller-link a.current {
  background-color: #056256;
  border-color: #056256;
  color: white;
  pointer-events: none;
  touch-action: none;
}
.bulletin-tabs-block .bulletin-tabs-block-holder .bulletin-tabs {
  display: inline-block;
  width: 100%;
}
.bulletin-tabs-block .bulletin-tabs-block-holder .bulletin-tabs .tabs {
  display: inline-block;
  width: 100%;
}
.bulletin-tabs-block .bulletin-tabs-block-holder .bulletin-tabs .tabs .tab {
  display: inline-block;
  width: 100%;
}

.inner-tab {
  display: inline-block;
  width: 100%;
}
.inner-tab .inner-tab-holder {
  display: inline-block;
  width: 100%;
}
.inner-tab .inner-tab-holder .iconed-links-block {
  margin: 0 0 20px;
}
.inner-tab .inner-tab-holder .iconed-links-block .iconed-links-block-holder .link-item .link-item-holder .link {
  padding: 23px 26px 26px;
}
.inner-tab .inner-tab-holder .link-buttons-block {
  display: inline-block;
  width: 100%;
}
.inner-tab .inner-tab-holder .link-buttons-block .link-buttons-block-holder {
  display: inline-block;
  width: 100%;
  background-color: white;
  box-sizing: border-box;
  padding: 38px 40px 40px;
  overflow: hidden;
}
.inner-tab .inner-tab-holder .link-buttons-block .link-buttons-block-holder .heading {
  display: inline-block;
  width: 100%;
  margin: 0 0 25px;
}
.inner-tab .inner-tab-holder .link-buttons-block .link-buttons-block-holder .note {
  display: inline-block;
  width: 100%;
  color: #666666;
  font-weight: 400;
  margin: 0 0 25px;
}
.inner-tab .inner-tab-holder .link-buttons-block .link-buttons-block-holder .button-links {
  display: inline-block;
  width: calc(100% + 15px);
  margin-bottom: -16px;
}
.inner-tab .inner-tab-holder .link-buttons-block .link-buttons-block-holder .button-links .btn {
  float: left;
  margin-right: 12px;
  margin-bottom: 16px;
  background-color: #F8F8F8;
  border-color: #F8F8F8;
  font-weight: 400;
  padding: 10px 25px;
  font-size: 18px;
}
.inner-tab .inner-tab-holder .link-buttons-block .link-buttons-block-holder .button-links .btn:hover {
  background-color: #056256;
  border-color: #056256;
  color: white;
}
.inner-tab .inner-tab-holder .iframe-block {
  display: inline-block;
  width: 100%;
}
.inner-tab .inner-tab-holder .iframe-block .iframe-block-holder {
  display: inline-block;
  width: 100%;
  background-color: white;
  overflow: hidden;
  box-sizing: border-box;
  padding: 80px 30px;
}
.inner-tab .inner-tab-holder .iframe-block .iframe-block-holder .inner-holder {
  display: inline-block;
  width: 100%;
  text-align: center;
}
.inner-tab .inner-tab-holder .iframe-block .iframe-block-holder .inner-holder > img {
  max-width: 100%;
}
.inner-tab .inner-tab-holder > .text.variant-2 {
  padding: 30px;
}
.inner-tab .inner-tab-holder > .text.variant-2 h3, .inner-tab .inner-tab-holder > .text.variant-2 .h3 {
  margin: 0 0 30px;
}
.inner-tab .inner-tab-holder > .text.variant-2 p:not(:last-of-type) {
  margin: 0 0 30px;
}
.inner-tab .inner-tab-holder > .text.variant-2 ul, .inner-tab .inner-tab-holder > .text.variant-2 ol {
  margin-top: 17px;
  padding-left: 25px;
  margin-bottom: 30px;
}
.inner-tab .inner-tab-holder > .text.variant-2 ul li:not(:last-of-type), .inner-tab .inner-tab-holder > .text.variant-2 ol li:not(:last-of-type) {
  margin: 0 0 11px;
}
.inner-tab .inner-tab-holder > .text {
  display: inline-block;
  width: 100%;
  box-sizing: border-box;
  padding: 37px 40px 40px;
  background-color: white;
  position: relative;
  z-index: 2;
}
.inner-tab .inner-tab-holder > .text .image {
  padding-bottom: 30px;
}
.inner-tab .inner-tab-holder > .text .image.left {
  float: left;
  padding-right: 30px;
}
.inner-tab .inner-tab-holder > .text .image.right {
  float: right;
  padding-left: 30px;
}
.inner-tab .inner-tab-holder > .text .image.full {
  width: 100%;
}
.inner-tab .inner-tab-holder > .text .image.full img {
  width: 100%;
}
.inner-tab .inner-tab-holder > .text .bkg-image {
  position: absolute;
  z-index: 1;
  right: 123px;
  top: 34px;
}
.inner-tab .inner-tab-holder > .text .heading {
  margin: 0 0 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.inner-tab .inner-tab-holder > .text .heading .author {
  white-space: nowrap;
}
.inner-tab .inner-tab-holder > .text h3, .inner-tab .inner-tab-holder > .text .h3 {
  margin: 0 0 25px;
}
.inner-tab .inner-tab-holder > .text h4, .inner-tab .inner-tab-holder > .text .h4 {
  margin: 0 0 15px;
}
.inner-tab .inner-tab-holder > .text p.no-margin {
  margin: 0;
}
.inner-tab .inner-tab-holder > .text p.no-margin:not(:last-of-type) {
  margin: 0;
}
.inner-tab .inner-tab-holder > .text p:not(:last-of-type) {
  margin: 0 0 24px;
}
.inner-tab .inner-tab-holder > .text ul, .inner-tab .inner-tab-holder > .text ol {
  padding-left: 27px;
}
.inner-tab .inner-tab-holder > .text ul li:not(:last-of-type), .inner-tab .inner-tab-holder > .text ol li:not(:last-of-type) {
  margin: 0 0 10px;
}
.inner-tab .inner-tab-holder > .text > .action {
  display: inline-block;
  width: 100%;
  margin: 14px 0 0;
}
.inner-tab .inner-tab-holder > .text > .action.big-gap {
  margin: 25px 0 0;
}
.inner-tab .inner-tab-holder > .text > .action .btn {
  min-width: 240px;
  text-align: center;
}
.inner-tab .inner-tab-holder > .text .note {
  display: inline-flex;
  align-items: center;
  padding: 10px;
  background-color: #F8F8F8;
  border-radius: 100px;
  color: #056256;
  font-size: 14px;
  line-height: 140%;
  margin: 26px 0 0;
}
.inner-tab .inner-tab-holder > .text .note.bigger-padding {
  padding: 15px;
}
.inner-tab .inner-tab-holder > .text .note.no-margin {
  margin: 0;
}
.inner-tab .inner-tab-holder > .text .note .icon {
  width: 24px;
  height: 24px;
}
.inner-tab .inner-tab-holder > .text .note .icon svg {
  width: 100%;
  height: 100%;
}
.inner-tab .inner-tab-holder > .text .note .text {
  width: calc(100% - 24px);
  box-sizing: border-box;
  padding-left: 10px;
}
.inner-tab .inner-tab-holder > .text .text-group {
  display: inline-block;
  width: 100%;
}
.inner-tab .inner-tab-holder > .text .text-group:not(:last-of-type) {
  margin: 0 0 40px;
}
.inner-tab .inner-tab-holder > .text .info-text {
  display: inline-block;
  width: 100%;
}
.inner-tab .inner-tab-holder > .text .info-text p {
  color: #666666;
}
.inner-tab .inner-tab-holder > .text .info-text p:not(:last-of-type) {
  margin: 0 0 15px;
}
.inner-tab .inner-tab-holder > .text .info-text a {
  text-decoration: none;
}
.inner-tab .inner-tab-holder > .text .small-text {
  width: 100%;
  margin: 0 0 20px;
  color: #666666;
}
.inner-tab .inner-tab-holder > .text .small-text p:not(:last-of-type) {
  margin: 0 0 9px;
}
.inner-tab .inner-tab-holder > .text .small-text ul, .inner-tab .inner-tab-holder > .text .small-text ol {
  line-height: 140%;
  margin-top: 16px;
  margin-bottom: 21px;
}
.inner-tab .inner-tab-holder > .text .small-text ul li:not(:last-of-type), .inner-tab .inner-tab-holder > .text .small-text ol li:not(:last-of-type) {
  margin: 0 0 4px;
}
.inner-tab .inner-tab-holder .reviews-slider {
  width: 100%;
  padding-bottom: 45px;
  margin-bottom: 18px;
}
.inner-tab .inner-tab-holder .reviews-slider .nav ul.slick-dots {
  bottom: 0;
}
.inner-tab .inner-tab-holder .reviews-slider .reviews-slider-holder .review-slide-item {
  width: 100%;
}
.inner-tab .inner-tab-holder .reviews-slider .reviews-slider-holder .review-slide-item .review-slide-item-holder {
  width: 100%;
  height: 418px;
}
.inner-tab .inner-tab-holder .reviews-slider .reviews-slider-holder .review-slide-item .review-slide-item-holder .image {
  width: 100%;
  height: 100%;
  text-align: center;
}
.inner-tab .inner-tab-holder .reviews-slider .reviews-slider-holder .review-slide-item .review-slide-item-holder .image img {
  height: 100%;
  max-width: 100%;
  object-fit: contain;
}
.inner-tab .inner-tab-holder .socials-links {
  display: flex;
  align-items: center;
  width: 100%;
}
.inner-tab .inner-tab-holder .socials-links .text {
  float: left;
  font-size: 16px;
  margin-right: 16px;
}
.inner-tab .inner-tab-holder .socials-links .links {
  float: left;
  margin: 0 -10px;
}
.inner-tab .inner-tab-holder .socials-links .links .link {
  float: left;
  margin: 0 10px;
}
.inner-tab .inner-tab-holder .socials-links .links .link a {
  display: inline-block;
  width: 48px;
  height: 48px;
  line-height: 44px;
  border-radius: 50%;
  background-color: #056256;
  color: white;
  text-align: center;
}
.inner-tab .inner-tab-holder .socials-links .links .link a:hover {
  background-color: #0ac3ab;
}
.inner-tab .inner-tab-holder .socials-links .links .link a svg {
  vertical-align: middle;
}
.inner-tab .inner-tab-holder .about-text {
  display: inline-block;
  width: 100%;
}
.inner-tab .inner-tab-holder .about-text .about-text-holder {
  display: inline-block;
  width: 100%;
  box-sizing: border-box;
  padding: 37px 40px 40px;
  background-color: white;
}
.inner-tab .inner-tab-holder .about-text .about-text-holder .image {
  width: 298px;
  float: left;
}
.inner-tab .inner-tab-holder .about-text .about-text-holder .image .image-holder {
  display: inline-block;
  width: 100%;
}
.inner-tab .inner-tab-holder .about-text .about-text-holder .image .image-holder img {
  display: inline-block;
  width: 100%;
}
.inner-tab .inner-tab-holder .about-text .about-text-holder .text-desc {
  width: calc(100% - 298px);
  float: left;
  box-sizing: border-box;
  padding-left: 25px;
}
.inner-tab .inner-tab-holder .about-text .about-text-holder .text-desc .text-desc-holder {
  display: inline-block;
  width: 100%;
}
.inner-tab .inner-tab-holder .about-text .about-text-holder .text-desc .text-desc-holder h3, .inner-tab .inner-tab-holder .about-text .about-text-holder .text-desc .text-desc-holder .h3 {
  margin: 0 0 30px;
}
.inner-tab .inner-tab-holder .about-text .about-text-holder .text-desc .text-desc-holder p {
  color: #666666;
}
.inner-tab .inner-tab-holder .about-text .about-text-holder .text-desc .text-desc-holder .author {
  display: inline-block;
  width: 100%;
  margin: 21px 0 0;
}
.inner-tab .inner-tab-holder .about-text .about-text-holder .text-desc .text-desc-holder .author .name {
  display: inline-block;
  width: 100%;
  font-weight: bold;
  margin: 0 0 17px;
}
.inner-tab .inner-tab-holder .about-text .about-text-holder .text-desc .text-desc-holder .author .position {
  display: inline-block;
  width: 100%;
  color: #666666;
}
.inner-tab .inner-tab-holder .about-text .about-text-holder .text-desc .text-desc-holder .action {
  display: inline-block;
  width: 100%;
  margin: 35px 0 0;
}
.inner-tab .inner-tab-holder .about-text .about-text-holder .text-desc .text-desc-holder .action .btn {
  min-width: 239px;
  text-align: center;
  padding: 9.5px 10px;
}

@media (max-width: 1279px) {
  .bulletin-tabs-block .bulletin-tabs-block-holder {
    padding: 23px 25px 25px;
  }
  .bulletin-tabs-block .bulletin-tabs-block-holder .bulletin-tabs-controlls {
    margin: 0 0 20px;
  }
  .bulletin-tabs-block .bulletin-tabs-block-holder .bulletin-tabs-controlls .bulletin-tabs-controlls-holder .controller-link {
    margin: 0 4px 8px;
  }
  .bulletin-tabs-block .bulletin-tabs-block-holder .bulletin-tabs-controlls .bulletin-tabs-controlls-holder .controller-link a {
    font-size: 14px;
    line-height: 130%;
    padding: 11px 15px;
  }
  .inner-tab .inner-tab-holder .link-buttons-block .link-buttons-block-holder {
    padding: 27px 25px 25px;
  }
  .inner-tab .inner-tab-holder .link-buttons-block .link-buttons-block-holder .heading {
    margin: 0 0 20px;
  }
  .inner-tab .inner-tab-holder .link-buttons-block .link-buttons-block-holder .note {
    margin: 0 0 20px;
  }
  .inner-tab .inner-tab-holder .link-buttons-block .link-buttons-block-holder .button-links .btn {
    margin-right: 5px;
    margin-bottom: 5px;
    font-size: 16px;
    padding: 10px 10px;
  }
  .inner-tab .inner-tab-holder .iframe-block .iframe-block-holder {
    padding: 30px 25px;
  }
  .inner-tab .inner-tab-holder > .text.variant-2 {
    padding: 25px;
  }
  .inner-tab .inner-tab-holder > .text.variant-2 h3, .inner-tab .inner-tab-holder > .text.variant-2 .h3 {
    margin: 0 0 32px;
  }
  .inner-tab .inner-tab-holder > .text.variant-2 p:not(:last-of-type) {
    margin: 0 0 15px;
  }
  .inner-tab .inner-tab-holder > .text.variant-2 ul, .inner-tab .inner-tab-holder > .text.variant-2 ol {
    margin-top: 15px;
    padding-left: 25px;
    margin-bottom: 20px;
  }
  .inner-tab .inner-tab-holder > .text.variant-2 ul li:not(:last-of-type), .inner-tab .inner-tab-holder > .text.variant-2 ol li:not(:last-of-type) {
    margin: 0 0 10px;
  }
  .inner-tab .inner-tab-holder > .text {
    padding: 27px 25px 25px;
  }
  .inner-tab .inner-tab-holder > .text .heading {
    margin: 0 0 29px;
  }
  .inner-tab .inner-tab-holder > .text p:not(:last-of-type) {
    margin: 0 0 20px;
  }
  .inner-tab .inner-tab-holder > .text .note {
    font-size: 12px;
    margin: 17px 0 0;
  }
  .inner-tab .inner-tab-holder > .text > .action {
    margin: 10px 0 0;
  }
  .inner-tab .inner-tab-holder > .text > .action.big-gap {
    margin: 20px 0 0;
  }
  .inner-tab .inner-tab-holder > .text .text-group {
    display: inline-block;
    width: 100%;
  }
  .inner-tab .inner-tab-holder > .text .text-group:not(:last-of-type) {
    margin: 0 0 20px;
  }
  .inner-tab .inner-tab-holder .socials-links .text {
    font-size: 14px;
    margin-right: 10px;
  }
  .inner-tab .inner-tab-holder .socials-links .links {
    margin: 0 -5px;
  }
  .inner-tab .inner-tab-holder .socials-links .links .link {
    margin: 0 5px;
  }
  .inner-tab .inner-tab-holder .socials-links .links .link a {
    width: 40px;
    height: 40px;
    line-height: 37px;
  }
  .inner-tab .inner-tab-holder .socials-links .links .link a svg {
    width: 21px;
    height: auto;
  }
  .inner-tab .inner-tab-holder .about-text .about-text-holder {
    padding: 27px 25px 25px;
  }
  .inner-tab .inner-tab-holder .about-text .about-text-holder .text-desc {
    padding-left: 20px;
  }
  .inner-tab .inner-tab-holder .about-text .about-text-holder .text-desc .text-desc-holder h3, .inner-tab .inner-tab-holder .about-text .about-text-holder .text-desc .text-desc-holder .h3 {
    margin: 0 0 20px;
  }
  .inner-tab .inner-tab-holder .about-text .about-text-holder .text-desc .text-desc-holder .author {
    margin: 15px 0 0;
  }
  .inner-tab .inner-tab-holder .about-text .about-text-holder .text-desc .text-desc-holder .author .name {
    margin: 0 0 15px;
  }
  .inner-tab .inner-tab-holder .about-text .about-text-holder .text-desc .text-desc-holder .action {
    margin: 20px 0 0;
  }
}
@media (max-width: 767px) {
  .bulletin-tabs-block.xs-reverse {
    margin: -15px 0 0;
  }
  .bulletin-tabs-block.xs-reverse .bulletin-tabs-block-holder {
    background-color: white;
  }
  .bulletin-tabs-block.xs-reverse .bulletin-tabs-block-holder .bulletin-tabs-controlls {
    margin: 0;
  }
  .bulletin-tabs-block.xs-reverse .bulletin-tabs-block-holder .bulletin-tabs-controlls .bulletin-tabs-controlls-holder .controller-link a {
    background-color: #F8F8F8;
    border-color: #F8F8F8;
  }
  .bulletin-tabs-block.xs-reverse .bulletin-tabs-block-holder .bulletin-tabs-controlls .bulletin-tabs-controlls-holder .controller-link a.current {
    background-color: #056256;
    border-color: #056256;
    color: white;
  }
  .bulletin-tabs-block.xs-reverse .inner-tab .inner-tab-holder .plaque-links-block .plaque-links-block-holder .plaque-link .plaque-link-holder a {
    background-color: #F8F8F8;
  }
  .bulletin-tabs-block.xs-reverse .inner-tab .inner-tab-holder > .text.variant-2 {
    padding: 17px 19px 20px;
    background-color: #F8F8F8;
  }
  .bulletin-tabs-block.xs-reverse .inner-tab .inner-tab-holder > .text.variant-2 .plaque-links-block .plaque-links-block-holder .plaque-link .plaque-link-holder a {
    background-color: white;
  }
  .bulletin-tabs-block .bulletin-tabs-block-holder {
    width: 110.35%;
    margin-left: -5.175%;
    box-sizing: border-box;
    padding: 15px 5.175%;
  }
  .bulletin-tabs-block .bulletin-tabs-block-holder .bulletin-tabs-controlls .bulletin-tabs-controlls-holder {
    width: 100%;
    margin: 0;
  }
  .bulletin-tabs-block .bulletin-tabs-block-holder .bulletin-tabs-controlls .bulletin-tabs-controlls-holder .controller-link {
    margin: 0 0 3px;
    width: 100%;
  }
  .bulletin-tabs-block .bulletin-tabs-block-holder .bulletin-tabs-controlls .bulletin-tabs-controlls-holder .controller-link a {
    width: 100%;
    font-size: 14px;
    line-height: 20px;
    padding: 10.5px 15px;
  }
  .bulletin-tabs-block .bulletin-tabs-block-holder .bulletin-tabs .tabs {
    width: 110.35%;
    margin-left: -5.175%;
    box-sizing: border-box;
  }
  .bulletin-tabs-block .bulletin-tabs-block-holder .bulletin-tabs .tabs .tab {
    padding: 15px 5.175% 0;
    box-sizing: border-box;
  }
  .inner-tab .inner-tab-holder .plaque-links-block .plaque-links-block-holder .plaque-link .plaque-link-holder a {
    background-color: white;
  }
  .inner-tab .inner-tab-holder .iconed-links-block {
    margin: 0 0 20px;
  }
  .inner-tab .inner-tab-holder .iconed-links-block .iconed-links-block-holder .link-item .link-item-holder .link {
    padding: 15px 25px 15px;
  }
  .inner-tab .inner-tab-holder .reviews-slider .reviews-slider-holder.slick-slider .slick-list {
    overflow: visible;
  }
  .inner-tab .inner-tab-holder .reviews-slider .reviews-slider-holder .review-slide-item .review-slide-item-holder {
    height: 380px;
  }
  .inner-tab .inner-tab-holder .about-text .about-text-holder {
    background-color: transparent;
    padding: 0;
    display: flex;
    flex-direction: column-reverse;
  }
  .inner-tab .inner-tab-holder .about-text .about-text-holder .image {
    width: 100%;
    margin: 0;
  }
  .inner-tab .inner-tab-holder .about-text .about-text-holder .text-desc {
    width: 100%;
    padding: 0;
    margin: 0 0 20px;
  }
  .inner-tab .inner-tab-holder .about-text .about-text-holder .text-desc .text-desc-holder h3, .inner-tab .inner-tab-holder .about-text .about-text-holder .text-desc .text-desc-holder .h3 {
    margin: 0 0 20px;
  }
  .inner-tab .inner-tab-holder .about-text .about-text-holder .text-desc .text-desc-holder .author {
    margin: 15px 0 0;
  }
  .inner-tab .inner-tab-holder .about-text .about-text-holder .text-desc .text-desc-holder .author .name {
    margin: 0 0 10px;
  }
  .inner-tab .inner-tab-holder .about-text .about-text-holder .text-desc .text-desc-holder .action {
    margin: 20px 0 0;
  }
  .inner-tab .inner-tab-holder .about-text .about-text-holder .text-desc .text-desc-holder .action .btn {
    min-width: auto;
    width: 100%;
  }
  .inner-tab .inner-tab-holder .link-buttons-block .link-buttons-block-holder {
    background-color: transparent;
    padding: 0;
    overflow: visible;
  }
  .inner-tab .inner-tab-holder .link-buttons-block .link-buttons-block-holder .heading {
    margin: 0 0 20px;
  }
  .inner-tab .inner-tab-holder .link-buttons-block .link-buttons-block-holder .note {
    margin: 0 0 20px;
  }
  .inner-tab .inner-tab-holder .link-buttons-block .link-buttons-block-holder .button-links {
    width: calc(100% + 5px);
    margin-bottom: -5px;
  }
  .inner-tab .inner-tab-holder .link-buttons-block .link-buttons-block-holder .button-links .btn {
    margin-right: 5px;
    margin-bottom: 5px;
    background-color: white;
    border-color: white;
    padding: 5px 15px;
    font-size: 14px;
    width: 100%;
  }
  .inner-tab .inner-tab-holder .iframe-block .iframe-block-holder {
    padding: 0;
    background-color: none;
  }
  .inner-tab .inner-tab-holder > .text.variant-2 {
    padding: 0;
    background-color: transparent;
  }
  .inner-tab .inner-tab-holder > .text.variant-2 h3, .inner-tab .inner-tab-holder > .text.variant-2 .h3 {
    margin: 0 0 30px;
  }
  .inner-tab .inner-tab-holder > .text.variant-2 p:not(:last-of-type) {
    margin: 0 0 10px;
  }
  .inner-tab .inner-tab-holder > .text {
    padding: 0;
    background-color: transparent;
  }
  .inner-tab .inner-tab-holder > .text .bkg-image {
    right: 50%;
    top: 34px;
    transform: translateX(50%);
  }
  .inner-tab .inner-tab-holder > .text .heading {
    flex-direction: column-reverse;
    align-items: start;
    margin: 0 0 20px;
  }
  .inner-tab .inner-tab-holder > .text .heading .heading-text h3, .inner-tab .inner-tab-holder > .text .heading .heading-text .h3 {
    line-height: 130%;
  }
  .inner-tab .inner-tab-holder > .text .heading .author {
    margin: 0 0 11px;
  }
  .inner-tab .inner-tab-holder > .text h4, .inner-tab .inner-tab-holder > .text .h4 {
    margin: 0 0 10px;
  }
  .inner-tab .inner-tab-holder > .text p {
    line-height: 22px;
  }
  .inner-tab .inner-tab-holder > .text .note {
    background-color: white;
    font-size: 14px;
    line-height: 20px;
    padding: 5px;
    margin: 12px 0 0;
    border-radius: 10px;
  }
  .inner-tab .inner-tab-holder > .text .note .icon {
    width: 16px;
    height: 16px;
  }
  .inner-tab .inner-tab-holder > .text .note .text {
    width: calc(100% - 16px);
    box-sizing: border-box;
    padding-left: 5px;
  }
  .inner-tab .inner-tab-holder > .text > .action .btn {
    min-width: none;
    width: 100%;
    text-align: center;
  }
}
.common-form-block {
  display: inline-block;
  width: 100%;
}
.common-form-block .common-form-block-holder {
  float: left;
  max-width: 845px;
}
.common-form-block .common-form-block-holder .note {
  display: inline-block;
  width: 100%;
  color: #666666;
  line-height: 140%;
  margin: -3px 0 28px;
}
.common-form-block .common-form-block-holder .note a {
  color: #056256;
  text-decoration: underline;
  word-break: break-all;
  text-decoration-color: currentColor;
}
.common-form-block .common-form-block-holder .note a:hover {
  color: #0ac3ab;
  text-decoration-color: transparent;
}
.common-form-block .common-form-block-holder .form {
  display: inline-block;
  width: 100%;
}
.common-form-block .common-form-block-holder .form form .form-group .form-col .form-field {
  margin-bottom: 15px;
}
.common-form-block .common-form-block-holder .form form .form-group .form-col .form-field textarea {
  height: 227px;
  padding: 15px 10px 0 14px;
}
.common-form-block .common-form-block-holder .form form .action {
  margin: 13px 0 0;
}
@media (max-width: 1279px) {
  .common-form-block .common-form-block-holder {
    max-width: none;
    width: 100%;
  }
  .common-form-block .common-form-block-holder .note {
    line-height: 130%;
  }
  .common-form-block .common-form-block-holder .form form .form-group .form-col .form-field {
    margin-bottom: 16px;
  }
  .common-form-block .common-form-block-holder .form form .form-group .form-col .form-field .input textarea {
    height: 208px;
  }
  .common-form-block .common-form-block-holder .form form .action .agree-col {
    font-size: 12px;
  }
}
@media (max-width: 767px) {
  .common-form-block .common-form-block-holder .note {
    margin: 0 0 20px;
    font-size: 16px;
    line-height: 22px;
  }
  .common-form-block .common-form-block-holder .form form .form-group.xs-float-anchor {
    position: relative;
    padding: 0 0 50px;
  }
  .common-form-block .common-form-block-holder .form form .form-group .form-col .form-field {
    margin-bottom: 11px;
  }
  .common-form-block .common-form-block-holder .form form .form-group .form-col .form-field.xs-float-to-bottom {
    position: absolute;
    bottom: 0;
    left: 0;
  }
  .common-form-block .common-form-block-holder .form form .form-group .form-col .form-field .input textarea {
    height: 100px;
    padding: 13px 10px 0 14px;
  }
  .common-form-block .common-form-block-holder .form form .action {
    margin: 7px 0 0;
  }
  .common-form-block .common-form-block-holder .form form .action .agree-col {
    font-size: 14px;
    line-height: 20px;
  }
}
.common-section-header {
  display: inline-block;
  width: 100%;
  margin: 0 0 30px;
}
.common-section-header .common-section-header-holder {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.common-section-header .common-section-header-holder .action a {
  display: flex;
  align-items: center;
  color: #1D1D1B;
}
.common-section-header .common-section-header-holder .action a:hover {
  color: #056256;
  transition: all 0.3s cubic-bezier(0.45, 0, 0.55, 1);
}
.common-section-header .common-section-header-holder .action a:hover .icon {
  color: #056256;
  transform: translateX(5px);
  transition: all 0.3s cubic-bezier(0.45, 0, 0.55, 1);
}
.common-section-header .common-section-header-holder .action a .icon {
  width: 16px;
  height: 10px;
  margin-left: 10px;
  color: #1D1D1B;
}
.common-section-header .common-section-header-holder .action a .icon svg {
  width: 100%;
  height: 100%;
}

@media (max-width: 767px) {
  .common-section-header .common-section-header-holder .action a {
    font-size: 16px;
    line-height: 22px;
  }
  .common-section-header .common-section-header-holder .action a .icon {
    width: 10px;
    height: 7px;
    margin-left: 5px;
  }
}
.division-detail-block {
  display: inline-block;
  width: 100%;
}
.division-detail-block .division-detail-block-holder {
  display: inline-block;
  width: 100%;
}
.division-detail-block .division-detail-block-holder > .back {
  display: inline-block;
  width: 100%;
  margin: 0 0 30px;
}
.division-detail-block .division-detail-block-holder > .back a {
  float: left;
  background-color: #F8F8F8;
  border-color: #F8F8F8;
  padding: 9.5px 39px;
}
.division-detail-block .division-detail-block-holder > .back a:hover {
  background-color: #056256;
  border-color: #056256;
  color: white;
}
.division-detail-block .division-detail-block-holder > .back a .icon {
  float: left;
  margin-right: 10px;
  width: 16px;
  height: 9px;
  padding: 7px 0 0;
}
.division-detail-block .division-detail-block-holder > .back a .icon svg {
  width: 100%;
  height: auto;
}
.division-detail-block .division-detail-block-holder > .back a .text {
  float: left;
}
.division-detail-block .division-detail-block-holder > .heading {
  display: inline-block;
  width: 100%;
  margin: 0 0 33px;
}
.division-detail-block .division-detail-block-holder > .heading .heading-holder {
  display: inline-block;
  width: 100%;
}
.division-detail-block .division-detail-block-holder .division-detail {
  display: inline-block;
  width: 100%;
}
.division-detail-block .division-detail-block-holder .division-detail .division-detail-holder {
  display: inline-block;
  width: 100%;
}

.division-detail-card {
  display: inline-block;
  width: 100%;
}
.division-detail-card .division-detail-card-holder {
  display: inline-block;
  width: 100%;
  background-color: white;
  overflow: hidden;
  box-sizing: border-box;
  padding: 30px;
}
.division-detail-card .division-detail-card-holder .card-info {
  display: inline-block;
  width: 100%;
}
.division-detail-card .division-detail-card-holder .card-info .card-info-holder {
  display: flex;
  align-items: center;
  width: 100%;
  background-color: #F8F8F8;
  overflow: hidden;
  box-sizing: border-box;
  padding: 30px;
}
.division-detail-card .division-detail-card-holder .card-info .card-info-holder .image {
  float: left;
  width: 167px;
}
.division-detail-card .division-detail-card-holder .card-info .card-info-holder .image .image-holder {
  display: inline-block;
  width: 100%;
}
.division-detail-card .division-detail-card-holder .card-info .card-info-holder .image .image-holder svg {
  width: 100%;
  height: auto;
}
.division-detail-card .division-detail-card-holder .card-info .card-info-holder .desc {
  float: left;
  width: calc(100% - 167px);
  box-sizing: border-box;
  padding-left: 30px;
}
.division-detail-card .division-detail-card-holder .card-info .card-info-holder .desc .desc-holder {
  display: inline-block;
  width: 100%;
}
.division-detail-card .division-detail-card-holder .card-info .card-info-holder .desc .desc-holder .heading {
  display: inline-block;
  width: 100%;
  margin: 0 0 23px;
}
.division-detail-card .division-detail-card-holder .card-info .card-info-holder .desc .desc-holder .info-texts {
  display: inline-block;
  width: 100%;
  margin: 0 0 25px;
}
.division-detail-card .division-detail-card-holder .card-info .card-info-holder .desc .desc-holder .info-texts .info-text {
  float: left;
}
.division-detail-card .division-detail-card-holder .card-info .card-info-holder .desc .desc-holder .info-texts .info-text:not(:last-of-type) {
  margin-right: 30px;
}
.info-contacts {
  display: inline-block;
  width: 100%;
}
.info-contacts .info-contact {
  float: left;
}
.info-contacts .info-contact:not(:last-of-type) {
  margin-right: 30px;
}
.info-contacts .info-contact .info-contact-holder a {
  display: inline-flex;
  align-items: center;
  color: #1D1D1B;
}
.info-contacts .info-contact .info-contact-holder a:hover {
  color: #056256;
}
.info-contacts .info-contact .info-contact-holder a .icon {
  margin-right: 10px;
  width: 16px;
  height: 16px;
}
.info-contacts .info-contact .info-contact-holder a .icon svg {
  width: 100%;
  height: 100%;
}
.division-personel {
  display: inline-block;
  width: 100%;
}
.division-personel .division-personel-holder {
  display: inline-block;
  width: 100%;
  overflow: hidden;
  box-sizing: border-box;
  padding: 30px;
  background-color: white;
}
.division-personel .division-personel-holder .heading {
  display: inline-block;
  width: 100%;
  margin: 0 0 32px;
}
.division-personel .division-personel-holder .division-personel-overview {
  display: inline-block;
  width: 100%;
}
.division-personel .division-personel-holder .division-personel-overview .division-personel-overview-holder {
  display: inline-block;
  width: 100%;
  overflow: hidden;
  box-sizing: border-box;
  padding: 30px;
  background-color: #F8F8F8;
}
.division-personel .division-personel-holder .division-personel-overview .division-personel-overview-holder .image {
  display: inline-block;
  width: 100%;
}
.division-personel .division-personel-holder .division-personel-overview .division-personel-overview-holder .image .image-holder {
  display: inline-block;
  width: 100%;
  overflow: hidden;
}
.division-personel .division-personel-holder .division-personel-overview .division-personel-overview-holder .image .image-holder img {
  display: inline-block;
  width: 100%;
}
.division-personel .division-personel-holder .division-personel-overview .division-personel-overview-holder .personel-overview {
  display: inline-block;
  width: 100%;
  margin: 0 0 -4px;
}
.division-personel .division-personel-holder .division-personel-overview .division-personel-overview-holder .personel-overview .personel-overview-holder {
  display: inline-block;
  width: 100%;
}
.division-personel .division-personel-holder .division-personel-overview .division-personel-overview-holder .personel-overview .personel-overview-holder .personel {
  display: inline-block;
  width: 100%;
  margin: 0 0 4px;
}
.division-personel .division-personel-holder .division-personel-overview .division-personel-overview-holder .personel-overview .personel-overview-holder .personel .personel-holder {
  display: inline-block;
  width: 100%;
}
.division-personel .division-personel-holder .division-personel-overview .division-personel-overview-holder .personel-overview .personel-overview-holder .personel .personel-holder .position {
  display: inline-block;
  width: 100%;
  margin: 0 0 17px;
  font-weight: 700;
}
.division-personel .division-personel-holder .division-personel-overview .division-personel-overview-holder .personel-overview .personel-overview-holder .personel .personel-holder .name {
  display: inline-block;
  width: 100%;
  color: #666666;
  line-height: 22px;
}
.division-personel .division-personel-holder .personel-detail-info {
  display: inline-block;
  width: 100%;
}
.division-personel .division-personel-holder .personel-detail-info .personel-detail-info-holder {
  display: inline-block;
  width: 100%;
  background-color: #F8F8F8;
  box-sizing: border-box;
  padding: 30px;
}
.division-personel .division-personel-holder .personel-detail-info .personel-detail-info-holder .personel-image {
  float: left;
  width: 255px;
}
.division-personel .division-personel-holder .personel-detail-info .personel-detail-info-holder .personel-image .personel-image-holder {
  display: inline-block;
  width: 100%;
  overflow: hidden;
}
.division-personel .division-personel-holder .personel-detail-info .personel-detail-info-holder .personel-image .personel-image-holder img {
  display: inline-block;
  width: 100%;
}
.division-personel .division-personel-holder .personel-detail-info .personel-detail-info-holder .personel-info {
  float: left;
  width: calc(100% - 255px);
  box-sizing: border-box;
  padding-left: 30px;
}
.division-personel .division-personel-holder .personel-detail-info .personel-detail-info-holder .personel-info .personel-info-holder {
  display: inline-block;
  width: 100%;
}
.division-personel .division-personel-holder .personel-detail-info .personel-detail-info-holder .personel-info .personel-info-holder .position {
  display: inline-block;
  width: 100%;
  font-size: 14px;
  height: 20px;
  color: #666666;
}
.division-personel .division-personel-holder .personel-detail-info .personel-detail-info-holder .personel-info .personel-info-holder .name {
  display: inline-block;
  width: 100%;
}
.division-personel .division-personel-holder .personel-detail-info .personel-detail-info-holder .personel-info .personel-info-holder .info-contacts {
  margin: 21px 0 0;
}
.division-personel .division-personel-holder .personel-detail-info .personel-detail-info-holder .personel-info .personel-info-holder .info-contacts .info-contact .info-contact-holder a .icon {
  margin-right: 5px;
}
.division-personel .division-personel-holder .personel-detail-info .personel-detail-info-holder .personel-info .personel-info-holder > .text {
  display: inline-block;
  width: 100%;
  margin: 29px 0 0;
}
.division-personel .division-personel-holder .personel-detail-info .personel-detail-info-holder .personel-info .personel-info-holder > .text .text-holder {
  display: inline-block;
  width: 100%;
  color: #666666;
}
.division-personel .division-personel-holder .personel-detail-info .personel-detail-info-holder .personel-info .personel-info-holder > .text .text-holder p:not(:last-of-type) {
  margin: 0 0 10px;
}

@media (max-width: 1279px) {
  .division-detail-block .division-detail-block-holder > .back {
    margin: 0 0 20px;
  }
  .division-detail-block .division-detail-block-holder > .back a {
    padding: 9.5px 19px;
  }
  .division-detail-block .division-detail-block-holder > .back a .icon {
    padding: 5px 0 0;
  }
  .division-detail-block .division-detail-block-holder > .heading {
    margin: 0 0 18px;
  }
  .division-detail-card .division-detail-card-holder {
    padding: 20px;
  }
  .division-detail-card .division-detail-card-holder .card-info .card-info-holder {
    padding: 25px;
  }
  .division-detail-card .division-detail-card-holder .card-info .card-info-holder .image {
    width: 123px;
  }
  .division-detail-card .division-detail-card-holder .card-info .card-info-holder .desc {
    width: calc(100% - 123px);
    padding-left: 30px;
  }
  .division-detail-card .division-detail-card-holder .card-info .card-info-holder .desc .desc-holder .info-texts {
    margin: 0 0 20px;
  }
  .division-personel .division-personel-holder {
    padding: 20px;
  }
  .division-personel .division-personel-holder .heading {
    margin: 0 0 20px;
  }
  .division-personel .division-personel-holder .division-personel-overview .division-personel-overview-holder {
    padding: 25px;
  }
  .division-personel .division-personel-holder .division-personel-overview .division-personel-overview-holder .personel-overview .personel-overview-holder .personel .personel-holder .position {
    margin: 0 0 10px;
  }
  .division-personel .division-personel-holder .personel-detail-info .personel-detail-info-holder {
    padding: 25px;
  }
  .division-personel .division-personel-holder .personel-detail-info .personel-detail-info-holder .personel-image {
    width: 175px;
  }
  .division-personel .division-personel-holder .personel-detail-info .personel-detail-info-holder .personel-info {
    width: calc(100% - 175px);
    padding-left: 20px;
  }
  .division-personel .division-personel-holder .personel-detail-info .personel-detail-info-holder .personel-info .personel-info-holder .position {
    height: auto;
    margin: 0 0 10px;
  }
  .division-personel .division-personel-holder .personel-detail-info .personel-detail-info-holder .personel-info .personel-info-holder .info-contacts {
    margin: 10px 0 0;
  }
  .division-personel .division-personel-holder .personel-detail-info .personel-detail-info-holder .personel-info .personel-info-holder > .text {
    margin: 20px 0 0;
  }
}
@media (max-width: 767px) {
  .division-detail-block .division-detail-block-holder > .back a {
    width: 100%;
  }
  .division-detail-block .division-detail-block-holder > .heading {
    margin: 0 0 14px;
  }
  .division-detail-card .division-detail-card-holder {
    padding: 0;
    background-color: transparent;
  }
  .division-detail-card .division-detail-card-holder .card-info .card-info-holder {
    display: inline-block;
    padding: 20px;
  }
  .division-detail-card .division-detail-card-holder .card-info .card-info-holder .image {
    width: 100%;
    margin: 0 0 27px;
  }
  .division-detail-card .division-detail-card-holder .card-info .card-info-holder .image .image-holder {
    text-align: left;
  }
  .division-detail-card .division-detail-card-holder .card-info .card-info-holder .image .image-holder svg {
    width: 123px;
  }
  .division-detail-card .division-detail-card-holder .card-info .card-info-holder .desc {
    width: 100%;
    padding: 0;
  }
  .division-detail-card .division-detail-card-holder .card-info .card-info-holder .desc .desc-holder .heading {
    margin: 0 0 20px;
  }
  .division-detail-card .division-detail-card-holder .card-info .card-info-holder .desc .desc-holder .info-texts {
    margin: 0 0 17px;
  }
  .division-detail-card .division-detail-card-holder .card-info .card-info-holder .desc .desc-holder .info-texts .info-text {
    width: 100%;
    font-size: 16px;
    line-height: 22px;
  }
  .division-detail-card .division-detail-card-holder .card-info .card-info-holder .desc .desc-holder .info-texts .info-text:not(:last-of-type) {
    margin: 0 0 20px;
  }
  .info-contacts .info-contact {
    width: 100%;
  }
  .info-contacts .info-contact:not(:last-of-type) {
    margin: 0 0 20px;
  }
  .info-contacts .info-contact .info-contact-holder a {
    font-size: 16px;
    line-height: 22px;
  }
  .division-personel .division-personel-holder {
    padding: 0;
  }
  .division-personel .division-personel-holder .division-personel-overview .division-personel-overview-holder {
    padding: 0;
    background-color: transparent;
  }
  .division-personel .division-personel-holder .personel-detail-info .personel-detail-info-holder {
    padding: 0;
    background-color: transparent;
  }
  .division-personel .division-personel-holder .personel-detail-info .personel-detail-info-holder .personel-image {
    width: 100%;
    margin: 0 0 20px;
  }
  .division-personel .division-personel-holder .personel-detail-info .personel-detail-info-holder .personel-info {
    width: 100%;
    padding: 0;
  }
}
.division-history-block {
  display: inline-block;
  width: 100%;
}
.division-history-block .division-history-block-holder {
  display: inline-block;
  width: 100%;
  overflow: hidden;
  background-color: white;
  box-sizing: border-box;
  padding: 30px;
}
.division-history-block .division-history-block-holder > .text {
  display: inline-block;
  width: 100%;
}
.division-history-block .division-history-block-holder > .text h3, .division-history-block .division-history-block-holder > .text .h3 {
  margin: 0 0 30px;
}
.division-history-block .division-history-block-holder > .text p:not(:last-of-type) {
  margin: 0 0 30px;
}
.division-history-block .division-history-block-holder .historical-reference {
  display: inline-block;
  width: 100%;
}
.division-history-block .division-history-block-holder .historical-reference .historical-reference-holder {
  display: inline-block;
  width: 100%;
  overflow: hidden;
  background-color: #F8F8F8;
  box-sizing: border-box;
  padding: 30px;
}
.division-history-block .division-history-block-holder .historical-reference .historical-reference-holder .image.left {
  float: left;
  padding: 0 30px 30px 0;
}
.division-history-block .division-history-block-holder .historical-reference .historical-reference-holder .image.right {
  float: right;
  padding: 0 0 30px 30px;
}
.division-history-block .division-history-block-holder .historical-reference .historical-reference-holder .image .desc {
  margin: 34px 0 0;
  font-weight: 700;
}
.division-history-block .division-history-block-holder .historical-reference .historical-reference-holder p {
  color: #666666;
}
.division-history-block .division-history-block-holder .historical-reference .historical-reference-holder p:not(:last-of-type) {
  margin: 0 0 11px;
}

@media (max-width: 1279px) {
  .division-history-block .division-history-block-holder {
    padding: 20px;
  }
  .division-history-block .division-history-block-holder > .text h3, .division-history-block .division-history-block-holder > .text .h3 {
    margin: 0 0 20px;
  }
  .division-history-block .division-history-block-holder > .text p:not(:last-of-type) {
    margin: 0 0 20px;
  }
  .division-history-block .division-history-block-holder .historical-reference .historical-reference-holder {
    padding: 25px;
  }
  .division-history-block .division-history-block-holder .historical-reference .historical-reference-holder .image {
    max-width: 225px;
  }
  .division-history-block .division-history-block-holder .historical-reference .historical-reference-holder .image.left {
    float: left;
    padding: 0 15px 15px 0;
  }
  .division-history-block .division-history-block-holder .historical-reference .historical-reference-holder .image.right {
    float: right;
    padding: 0 0 15px 15px;
  }
  .division-history-block .division-history-block-holder .historical-reference .historical-reference-holder .image img {
    width: 100%;
  }
  .division-history-block .division-history-block-holder .historical-reference .historical-reference-holder .image .desc {
    margin: 20px 0 0;
  }
  .division-history-block .division-history-block-holder .historical-reference .historical-reference-holder p:not(:last-of-type) {
    margin: 0 0 10px;
  }
}
@media (max-width: 767px) {
  .division-history-block .division-history-block-holder {
    padding: 0;
    overflow: visible;
  }
  .division-history-block .division-history-block-holder > .text p:not(:last-of-type) {
    margin: 0 0 10px;
  }
  .division-history-block .division-history-block-holder .historical-reference .historical-reference-holder {
    padding: 0;
  }
  .division-history-block .division-history-block-holder .historical-reference .historical-reference-holder .image {
    max-width: none;
    width: 100%;
  }
  .division-history-block .division-history-block-holder .historical-reference .historical-reference-holder .image.left {
    float: left;
    padding: 0 0 20px;
  }
  .division-history-block .division-history-block-holder .historical-reference .historical-reference-holder .image.right {
    float: right;
    padding: 0 0 20px;
  }
  .division-history-block .division-history-block-holder .historical-reference .historical-reference-holder .image .desc {
    margin: 10px 0 0;
  }
  .division-history-block .division-history-block-holder .historical-reference .historical-reference-holder p:not(:last-of-type) {
    margin: 0 0 10px;
  }
}
.empty-search {
  display: inline-block;
  width: 100%;
}
.empty-search .empty-search-holder {
  display: inline-block;
  width: 100%;
  padding: 138px 0 140px;
}
.empty-search .empty-search-holder .text {
  display: inline-block;
  width: 100%;
  text-align: center;
  font-weight: 600;
}

@media (max-width: 1279px) {
  .empty-search .empty-search-holder {
    padding: 70px 0;
  }
}
@media (max-width: 767px) {
  .empty-search .empty-search-holder {
    padding: 35px 0;
  }
}
.iconed-links-block {
  display: inline-block;
  width: 100%;
}
.iconed-links-block .iconed-links-block-holder {
  display: inline-block;
  width: 100%;
}
.iconed-links-block .iconed-links-block-holder .cols .col.col-custom-70 {
  width: 70.5%;
}
.iconed-links-block .iconed-links-block-holder .cols .col.col-custom-30 {
  width: 29.5%;
}
.iconed-links-block .iconed-links-block-holder .cols .col.col-custom-50-minus-290 {
  width: calc(50% - 160px);
}
.iconed-links-block .iconed-links-block-holder .cols .col.col-custom-290 {
  width: 320px;
}
.iconed-links-block .iconed-links-block-holder .link-item.reverse .link-item-holder .link {
  background-color: white;
}
.iconed-links-block .iconed-links-block-holder .link-item {
  display: inline-block;
  width: 100%;
  height: 100%;
}
.iconed-links-block .iconed-links-block-holder .link-item .link-item-holder {
  display: inline-block;
  width: 100%;
  height: 100%;
}
.iconed-links-block .iconed-links-block-holder .link-item .link-item-holder .link.side-way-image {
  flex-direction: row;
  align-items: center;
}
.iconed-links-block .iconed-links-block-holder .link-item .link-item-holder .link.side-way-image .image {
  width: 115px;
  height: 115px;
  flex-grow: 0;
}
.iconed-links-block .iconed-links-block-holder .link-item .link-item-holder .link.side-way-image .image .image-holder {
  width: 100%;
  height: 100%;
}
.iconed-links-block .iconed-links-block-holder .link-item .link-item-holder .link.side-way-image .image .image-holder svg {
  width: 100%;
  height: auto;
}
.iconed-links-block .iconed-links-block-holder .link-item .link-item-holder .link.side-way-image > .text {
  width: calc(100% - 150px);
  box-sizing: border-box;
  padding-left: 30px;
}
.iconed-links-block .iconed-links-block-holder .link-item .link-item-holder a.link:hover > .text .text-holder .heading {
  color: #056256;
  transition: all 0.3s cubic-bezier(0.45, 0, 0.55, 1);
}
.iconed-links-block .iconed-links-block-holder .link-item .link-item-holder a.link:hover > .text .text-holder .action {
  color: #056256;
  transition: all 0.3s cubic-bezier(0.45, 0, 0.55, 1);
}
.iconed-links-block .iconed-links-block-holder .link-item .link-item-holder a.link:hover > .text .text-holder .action .icon {
  color: #056256;
  transform: translateX(5px);
  transition: all 0.3s cubic-bezier(0.45, 0, 0.55, 1);
}
.iconed-links-block .iconed-links-block-holder .link-item .link-item-holder .link {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: #F8F8F8;
  box-sizing: border-box;
  padding: 28px 30px 30px;
  color: #1D1D1B;
}
.iconed-links-block .iconed-links-block-holder .link-item .link-item-holder .link .image {
  display: inline-block;
  width: 100%;
  flex-grow: 1;
}
.iconed-links-block .iconed-links-block-holder .link-item .link-item-holder .link .image .image-holder {
  display: inline-block;
  width: 100%;
}
.iconed-links-block .iconed-links-block-holder .link-item .link-item-holder .link .image .image-holder svg {
  display: inline-block;
  width: 100%;
}
.iconed-links-block .iconed-links-block-holder .link-item .link-item-holder .link > .text {
  display: inline-block;
  width: 100%;
}
.iconed-links-block .iconed-links-block-holder .link-item .link-item-holder .link > .text .text-holder {
  display: inline-block;
  width: 100%;
}
.iconed-links-block .iconed-links-block-holder .link-item .link-item-holder .link > .text .text-holder .heading {
  display: inline-block;
  width: 100%;
  color: #1D1D1B;
  transition: all 0.3s cubic-bezier(0.45, 0, 0.55, 1);
}
.iconed-links-block .iconed-links-block-holder .link-item .link-item-holder .link > .text .text-holder .desc {
  display: inline-block;
  width: 100%;
  color: #666666;
  font-size: 14px;
  line-height: 140%;
  margin: 11px 0 0;
  color: #666666;
}
.iconed-links-block .iconed-links-block-holder .link-item .link-item-holder .link > .text .text-holder .desc.lines-2 {
  height: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -moz-box;
  -moz-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-clamp: 2;
  box-orient: vertical;
}
.iconed-links-block .iconed-links-block-holder .link-item .link-item-holder .link > .text .text-holder .big-action {
  display: inline-block;
  width: 100%;
  margin: 29px 0 0;
}
.iconed-links-block .iconed-links-block-holder .link-item .link-item-holder .link > .text .text-holder .big-action .btn {
  display: inline-block;
  width: 100%;
  text-align: center;
  padding: 9.5px 15px;
}
.iconed-links-block .iconed-links-block-holder .link-item .link-item-holder .link > .text .text-holder .action {
  display: inline-flex;
  align-items: center;
  width: 100%;
  color: #666666;
  font-size: 14px;
  transition: all 0.3s cubic-bezier(0.45, 0, 0.55, 1);
  margin: 20px 0 0;
}
.iconed-links-block .iconed-links-block-holder .link-item .link-item-holder .link > .text .text-holder .action .text {
  float: left;
}
.iconed-links-block .iconed-links-block-holder .link-item .link-item-holder .link > .text .text-holder .action .icon {
  float: left;
  width: 16px;
  height: 10px;
  margin-left: 10px;
  color: #666666;
  transition: all 0.3s cubic-bezier(0.45, 0, 0.55, 1);
}
.iconed-links-block .iconed-links-block-holder .link-item .link-item-holder .link > .text .text-holder .action .icon svg {
  width: 100%;
  height: 100%;
}

@media (max-width: 1470px) {
  .iconed-links-block .iconed-links-block-holder .cols .col.col-custom-70 {
    width: 62%;
  }
  .iconed-links-block .iconed-links-block-holder .cols .col.col-custom-30 {
    width: 38%;
  }
}
@media (max-width: 1279px) {
  .iconed-links-block .iconed-links-block-holder .cols .col.col-custom-70 {
    width: 100%;
  }
  .iconed-links-block .iconed-links-block-holder .cols .col.col-custom-30 {
    width: 100%;
  }
  .iconed-links-block .iconed-links-block-holder .cols .col.col-custom-50-minus-290 {
    width: 33.333%;
  }
  .iconed-links-block .iconed-links-block-holder .cols .col.col-custom-290 {
    width: 33.333%;
  }
  .iconed-links-block .iconed-links-block-holder .link-item .link-item-holder .link.side-way-image {
    flex-direction: column;
  }
  .iconed-links-block .iconed-links-block-holder .link-item .link-item-holder .link.side-way-image .image {
    width: 100%;
    height: auto;
  }
  .iconed-links-block .iconed-links-block-holder .link-item .link-item-holder .link.side-way-image > .text {
    width: 100%;
    padding: 0;
  }
  .iconed-links-block .iconed-links-block-holder .link-item .link-item-holder .link {
    padding: 16px 25px 16px;
  }
  .iconed-links-block .iconed-links-block-holder .link-item .link-item-holder .link .image {
    min-height: 222px;
  }
  .iconed-links-block .iconed-links-block-holder .link-item .link-item-holder .link > .text .text-holder .desc {
    font-size: 12px;
    margin: 13px 0 0;
  }
  .iconed-links-block .iconed-links-block-holder .link-item .link-item-holder .link > .text .text-holder .action {
    margin: 15px 0 12px;
  }
  .iconed-links-block .iconed-links-block-holder .link-item .link-item-holder .link > .text .text-holder .big-action {
    margin: 13px 0 0;
  }
}
@media (max-width: 767px) {
  .iconed-links-block .iconed-links-block-holder .cols .col.col-custom-50-minus-290 {
    width: 100%;
  }
  .iconed-links-block .iconed-links-block-holder .cols .col.col-custom-290 {
    width: 100%;
  }
  .iconed-links-block .iconed-links-block-holder .link-item .link-item-holder .link {
    padding: 15px 20px 17px;
  }
  .iconed-links-block .iconed-links-block-holder .link-item .link-item-holder .link > .text .text-holder .desc {
    font-size: 12px;
    line-height: 17px;
    margin: 7px 0 0;
  }
  .iconed-links-block .iconed-links-block-holder .link-item .link-item-holder .link > .text .text-holder .desc.lines-2 {
    height: auto;
    -webkit-line-clamp: 3;
    line-clamp: 3;
  }
  .iconed-links-block .iconed-links-block-holder .link-item .link-item-holder .link > .text .text-holder .big-action {
    margin: 15px 0 0;
  }
  .iconed-links-block .iconed-links-block-holder .link-item .link-item-holder .link > .text .text-holder .big-action .btn {
    padding: 9.5px 15px;
  }
  .iconed-links-block .iconed-links-block-holder .link-item .link-item-holder .link > .text .text-holder .action {
    font-size: 14px;
    margin: 18px 0 0;
  }
  .iconed-links-block .iconed-links-block-holder .link-item .link-item-holder .link > .text .text-holder .action .icon {
    width: 10px;
    height: 7px;
    margin-left: 5px;
  }
}
.gallery-block {
  display: inline-block;
  width: 100%;
}
.gallery-block .gallery-block-holder {
  display: inline-block;
  width: 100%;
  box-sizing: border-box;
  padding: 30px;
  background-color: #F8F8F8;
  overflow: hidden;
}
.gallery-block .gallery-block-holder .heading {
  display: flex;
  align-items: start;
  justify-content: space-between;
  width: 100%;
  margin: 0 0 30px;
}
.gallery-block .gallery-block-holder .heading .h3, .gallery-block .gallery-block-holder .heading h3 {
  max-width: 90%;
}
.gallery-block .gallery-block-holder .heading .date {
  color: #666666;
  font-size: 14px;
  line-height: 20px;
  padding: 5px 0 0;
}

.images-gallery.long .images-gallery-holder .images-gallery-list .images-gallery-list-holder .gallery-image .gallery-image-holder a .inner-image {
  margin-top: 105%;
}

.images-gallery {
  display: inline-block;
  width: 100%;
}
.images-gallery .images-gallery-holder {
  display: inline-block;
  width: 100%;
}
.images-gallery .images-gallery-holder .images-gallery-list.row-4 .images-gallery-list-holder .gallery-image {
  width: 25%;
}
.images-gallery .images-gallery-holder .images-gallery-list {
  display: inline-block;
  width: 100%;
}
.images-gallery .images-gallery-holder .images-gallery-list .images-gallery-list-holder {
  display: inline-block;
  width: calc(100% + 30px);
  margin-left: -15px;
  margin-bottom: -27px;
}
.images-gallery .images-gallery-holder .images-gallery-list .images-gallery-list-holder .gallery-image {
  float: left;
  width: 16.666%;
  box-sizing: border-box;
  padding: 0 15px;
  margin: 0 0 27px;
}
.images-gallery .images-gallery-holder .images-gallery-list .images-gallery-list-holder .gallery-image .gallery-image-holder {
  display: inline-block;
  width: 100%;
}
.images-gallery .images-gallery-holder .images-gallery-list .images-gallery-list-holder .gallery-image .gallery-image-holder a {
  display: inline-block;
  width: 100%;
  overflow: hidden;
  position: relative;
}
.images-gallery .images-gallery-holder .images-gallery-list .images-gallery-list-holder .gallery-image .gallery-image-holder a:hover .inner-image img {
  transform: scale(1.1);
  transition: all 0.3s cubic-bezier(0.45, 0, 0.55, 1);
}
.images-gallery .images-gallery-holder .images-gallery-list .images-gallery-list-holder .gallery-image .gallery-image-holder a .inner-image {
  display: inline-block;
  width: 100%;
  margin-top: 100%;
}
.images-gallery .images-gallery-holder .images-gallery-list .images-gallery-list-holder .gallery-image .gallery-image-holder a .inner-image img {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: all 0.3s cubic-bezier(0.45, 0, 0.55, 1);
}
.images-gallery .images-gallery-holder > .action {
  display: inline-block;
  width: 100%;
  margin: 22px 0 0;
}
.images-gallery .images-gallery-holder > .action.reverse .btn {
  background-color: white;
  border-color: white;
}
.images-gallery .images-gallery-holder > .action.reverse .btn:hover {
  background-color: #056256;
  border-color: #056256;
  color: white;
}
.images-gallery .images-gallery-holder > .action .btn {
  display: inline-block;
  width: 100%;
  text-align: center;
  background-color: #F8F8F8;
  border-color: #F8F8F8;
}
.images-gallery .images-gallery-holder > .action .btn:hover {
  background-color: #056256;
  border-color: #056256;
  color: white;
}

@media (max-width: 1279px) {
  .gallery-block .gallery-block-holder .heading {
    margin: 0 0 20px;
  }
  .gallery-block .gallery-block-holder .heading .date {
    font-size: 12px;
    line-height: 17px;
    padding: 0;
  }
  .images-gallery.long .images-gallery-holder .images-gallery-list .images-gallery-list-holder .gallery-image .gallery-image-holder a .inner-image {
    margin-top: 108%;
  }
  .images-gallery .images-gallery-holder .images-gallery-list .images-gallery-list-holder {
    width: calc(100% + 20px);
    margin-left: -10px;
    margin-bottom: -20px;
  }
  .images-gallery .images-gallery-holder .images-gallery-list .images-gallery-list-holder .gallery-image {
    width: 25%;
    padding: 0 10px;
    margin: 0 0 20px;
  }
  .images-gallery .images-gallery-holder > .action {
    margin: 15px 0 0;
  }
}
@media (max-width: 767px) {
  .gallery-block {
    float: left;
  }
  .gallery-block .gallery-block-holder {
    width: 110.35%;
    margin-left: -5.175%;
    padding: 27px 5.175% 15px;
  }
  .gallery-block .gallery-block-holder .heading {
    flex-direction: column-reverse;
  }
  .gallery-block .gallery-block-holder .heading h3, .gallery-block .gallery-block-holder .heading .h3 {
    max-width: none;
    width: 100%;
  }
  .gallery-block .gallery-block-holder .heading .date {
    width: 100%;
    font-size: 14px;
    line-height: 20px;
    margin: 0 0 4px;
  }
  .images-gallery.long .images-gallery-holder .images-gallery-list .images-gallery-list-holder .gallery-image .gallery-image-holder a .inner-image {
    margin-top: 104%;
  }
  .images-gallery .images-gallery-holder .images-gallery-list.row-4 .images-gallery-list-holder .gallery-image {
    width: 100%;
  }
  .images-gallery .images-gallery-holder .images-gallery-list .images-gallery-list-holder {
    width: 100%;
    margin-left: 0;
    margin-bottom: -20px;
  }
  .images-gallery .images-gallery-holder .images-gallery-list .images-gallery-list-holder.slick-slider {
    margin-left: -7.5px;
    width: calc(100% + 15px);
    box-sizing: border-box;
    padding: 0 6.5% 0 0;
  }
  .images-gallery .images-gallery-holder .images-gallery-list .images-gallery-list-holder.slick-slider .slick-list {
    overflow: visible;
  }
  .images-gallery .images-gallery-holder .images-gallery-list .images-gallery-list-holder .gallery-image {
    width: 100%;
    padding: 0;
    margin: 0 0 20px;
  }
  .images-gallery .images-gallery-holder .images-gallery-list .images-gallery-list-holder .gallery-image .gallery-image-holder a .inner-image {
    margin-top: 107%;
  }
}
.links-buttons-block {
  display: inline-block;
  width: 100%;
}
.links-buttons-block .links-buttons-block-holder {
  display: inline-block;
  width: 100%;
  box-sizing: border-box;
  padding: 30px;
}
.links-buttons-block .links-buttons-block-holder .heading {
  display: inline-block;
  width: 100%;
  margin: 0 0 33px;
}
.links-buttons-block .links-buttons-block-holder ul {
  display: inline-block;
  padding: 0;
  list-style: none;
  margin: 0 -4px -14px;
}
.links-buttons-block .links-buttons-block-holder ul li {
  float: left;
  padding: 0 4px 14px;
}
.links-buttons-block .links-buttons-block-holder ul li a {
  padding: 8.5px 20px;
  font-weight: 400;
}

@media (max-width: 1279px) {
  .links-buttons-block .links-buttons-block-holder {
    padding: 25px;
  }
  .links-buttons-block .links-buttons-block-holder ul {
    margin: 0 -4px -12px;
  }
  .links-buttons-block .links-buttons-block-holder ul li {
    padding: 0 4px 12px;
  }
}
@media (max-width: 767px) {
  .links-buttons-block .links-buttons-block-holder {
    padding: 0 0 15px;
  }
  .links-buttons-block .links-buttons-block-holder.no-xs-padding {
    padding: 0;
  }
  .links-buttons-block .links-buttons-block-holder.bg-secondary {
    background-color: white;
  }
  .links-buttons-block .links-buttons-block-holder.bg-secondary ul li a {
    background-color: #F8F8F8;
    border-color: #F8F8F8;
  }
  .links-buttons-block .links-buttons-block-holder .heading {
    margin: 0 0 15px;
  }
  .links-buttons-block .links-buttons-block-holder ul {
    margin: 0 0 -5px;
  }
  .links-buttons-block .links-buttons-block-holder ul li {
    width: 100%;
    padding: 0 0 5px;
  }
  .links-buttons-block .links-buttons-block-holder ul li a {
    width: 100%;
    padding: 9px 14px;
    font-size: 14px;
    line-height: 20px;
    border-radius: 15px;
  }
}
.local-search-block {
  display: inline-block;
  width: 100%;
}
.local-search-block .local-search-block-holder {
  display: inline-block;
  width: 100%;
}
.local-search-block .local-search-block-holder .from {
  display: inline-block;
  width: 100%;
}
.local-search-block .local-search-block-holder .from form {
  display: inline-block;
  width: 100%;
}
.local-search-block .local-search-block-holder .from form .form-field {
  display: inline-block;
  width: 100%;
  margin: 0;
}
.local-search-block .local-search-block-holder .from form .form-field .input {
  display: inline-block;
  width: 100%;
}
.local-search-block .local-search-block-holder .from form .form-field .input input {
  height: 54px;
  padding: 0 61px 0 20px;
}
.local-search-block .local-search-block-holder .from form .form-field .input .addon .btn {
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  padding: 0;
  background-color: white;
  border-color: white;
  border-radius: 50%;
  color: #056256;
}
.local-search-block .local-search-block-holder .from form .form-field .input .addon .btn svg {
  vertical-align: middle;
}

@media (max-width: 1279px) {
  .local-search-block .local-search-block-holder .from form .form-field .input input {
    height: 50px;
    font-size: 12px;
  }
  .local-search-block .local-search-block-holder .from form .form-field .input input::-webkit-input-placeholder {
    font-size: 12px;
  }
  .local-search-block .local-search-block-holder .from form .form-field .input input:-moz-placeholder {
    font-size: 12px;
  }
  .local-search-block .local-search-block-holder .from form .form-field .input input::-moz-placeholder {
    font-size: 12px;
  }
  .local-search-block .local-search-block-holder .from form .form-field .input input:-ms-input-placeholder {
    font-size: 12px;
  }
  .local-search-block .local-search-block-holder .from form .form-field .input .addon .btn {
    width: 40px;
    height: 40px;
    line-height: 40px;
  }
}
@media (max-width: 767px) {
  .local-search-block .local-search-block-holder .from form .form-field .input input {
    height: 40px;
    padding: 0 41px 0 15px;
  }
  .local-search-block .local-search-block-holder .from form .form-field .input .addon .btn {
    width: 30px;
    height: 30px;
    line-height: 30px;
  }
  .local-search-block .local-search-block-holder .from form .form-field .input .addon .btn svg {
    width: 10px;
    height: 10px;
  }
}
.login-link-block {
  display: inline-block;
  width: 100%;
}
.login-link-block .login-link-block-holder {
  display: inline-block;
  width: 100%;
}
.login-link-block .login-link-block-holder .login-link {
  display: inline-block;
  width: 100%;
}
.login-link-block .login-link-block-holder .login-link .login-link-holder {
  display: inline-block;
  width: 100%;
}
.login-link-block .login-link-block-holder .login-link .login-link-holder a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: #F8F8F8;
  overflow: hidden;
  color: #1D1D1B;
  box-sizing: border-box;
  padding: 28px 20px;
}
.login-link-block .login-link-block-holder .login-link .login-link-holder a:hover {
  color: #056256;
}
.login-link-block .login-link-block-holder .login-link .login-link-holder a .icon {
  width: 80px;
  height: 80px;
  margin-right: 25px;
}
.login-link-block .login-link-block-holder .login-link .login-link-holder a .icon .icon-holder {
  width: 100%;
  height: 100%;
}
.login-link-block .login-link-block-holder .login-link .login-link-holder a .icon .icon-holder svg {
  width: 100%;
  height: 100%;
}
.login-link-block .login-link-block-holder .login-link .login-link-holder a .text .text-holder {
  padding: 0 0 4px;
}
.login-link-block .login-link-block-holder .login-link .login-link-holder a .text .text-holder .heading {
  margin: 0 0 8px;
}
.login-link-block .login-link-block-holder .login-link .login-link-holder a .text .text-holder .desc {
  color: #666666;
}

@media (max-width: 1279px) {
  .login-link-block .login-link-block-holder .login-link .login-link-holder a {
    padding: 20px 10px;
  }
  .login-link-block .login-link-block-holder .login-link .login-link-holder a .icon {
    width: 60px;
    height: 60px;
    margin-right: 20px;
  }
}
@media (max-width: 767px) {
  .login-link-block .login-link-block-holder .login-link .login-link-holder a {
    display: inline-block;
  }
  .login-link-block .login-link-block-holder .login-link .login-link-holder a .icon {
    width: 100%;
    height: auto;
    margin: 0 0 10px;
    text-align: center;
  }
  .login-link-block .login-link-block-holder .login-link .login-link-holder a .icon .icon-holder {
    width: 40px;
    height: 40px;
    display: inline-block;
  }
  .login-link-block .login-link-block-holder .login-link .login-link-holder a .text .text-holder {
    padding: 0;
    text-align: center;
  }
  .login-link-block .login-link-block-holder .login-link .login-link-holder a .text .text-holder .heading {
    margin: 0 0 5px;
  }
}
.news-detail {
  display: inline-block;
  width: 100%;
  margin: 0 0 64px;
}
.news-detail .news-detail-holder {
  display: inline-block;
  width: 100%;
}
.news-detail .news-detail-holder .heading {
  display: inline-block;
  width: 100%;
  margin: 0 0 30px;
}
.news-detail .news-detail-holder .detail-image {
  display: inline-block;
  width: 100%;
  margin: 0 0 25px;
}
.news-detail .news-detail-holder .detail-image .detail-image-holder {
  display: inline-block;
  width: 100%;
  overflow: hidden;
}
.news-detail .news-detail-holder .detail-image .detail-image-holder img {
  display: inline-block;
  width: 100%;
  object-fit: cover;
}
.news-detail .news-detail-holder .text {
  display: inline-block;
  width: 100%;
}
.news-detail .news-detail-holder .text .text-holder {
  display: inline-block;
  width: 100%;
}
.news-detail .news-detail-holder .images-gallery {
  margin: 30px 0 0;
}
.news-detail .news-detail-holder .add-info {
  margin: 33px 0 0;
  color: #666666;
}

@media (max-width: 1279px) {
  .news-detail {
    margin: 0 0 43px;
  }
  .news-detail .news-detail-holder .text .text-holder p {
    line-height: 18px;
  }
}
@media (max-width: 767px) {
  .news-detail {
    overflow: hidden;
    margin: 0 0 29px;
  }
  .news-detail .news-detail-holder .heading {
    margin: 0 0 20px;
  }
  .news-detail .news-detail-holder .detail-image {
    margin: 0 0 17px;
  }
  .news-detail .news-detail-holder .text .text-holder p {
    font-size: 16px;
    line-height: 22px;
  }
  .news-detail .news-detail-holder .text .text-holder p:not(:last-of-type) {
    margin: 0 0 15px;
  }
  .news-detail .news-detail-holder .images-gallery {
    margin: 20px 0 0;
  }
  .news-detail .news-detail-holder .add-info {
    margin: 14px 0 0;
    font-size: 16px;
    line-height: 22px;
  }
}
.news-list-preview {
  display: inline-block;
  width: 100%;
}
.news-list-preview .news-list-preview-holder {
  display: inline-block;
  width: 100%;
}
.news-list-preview .news-list-preview-holder > .action {
  display: inline-block;
  width: 100%;
}
.news-list-preview .news-list-preview-holder > .action .btn {
  display: inline-block;
  width: 100%;
  text-align: center;
  background-color: #F8F8F8;
  border-color: #F8F8F8;
}
.news-list-preview .news-list-preview-holder > .action .btn:hover {
  background-color: #056256;
  border-color: #056256;
  color: white;
}

.news-slider {
  display: inline-block;
  width: 100%;
}
.news-plain-list {
  width: calc(100% + 30px);
  margin-left: -15px;
  margin-bottom: -30px;
}
.news-plain-list .news-item {
  display: block;
  float: left;
  width: 33.333%;
  box-sizing: border-box;
  padding: 0 15px;
  margin: 0 0 30px;
}

.news-item.reverse .news-item-holder a .desc {
  background-color: #F8F8F8;
}

.news-item {
  display: inline-block;
  width: 100%;
}
.news-item .news-item-holder {
  display: inline-block;
  width: 100%;
}
.news-item .news-item-holder a {
  display: inline-block;
  width: 100%;
  overflow: hidden;
  color: #1D1D1B;
}
.news-item .news-item-holder a:hover .image img {
  transform: scale(1.1);
  transition: all 0.3s cubic-bezier(0.45, 0, 0.55, 1);
}
.news-item .news-item-holder a:hover .desc .action {
  color: #056256;
  transition: all 0.3s cubic-bezier(0.45, 0, 0.55, 1);
}
.news-item .news-item-holder a:hover .desc .action .icon {
  color: #056256;
  transform: translateX(5px);
  transition: all 0.3s cubic-bezier(0.45, 0, 0.55, 1);
}
.news-item .news-item-holder a .image {
  display: inline-block;
  width: 100%;
  height: 300px;
  overflow: hidden;
}
.news-item .news-item-holder a .image img {
  display: inline-block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
  transition: all 0.3s cubic-bezier(0.45, 0, 0.55, 1);
}
.news-item .news-item-holder a .desc {
  display: inline-block;
  width: 100%;
  background-color: white;
  box-sizing: border-box;
  padding: 27px 30px 30px;
}
.news-item .news-item-holder a .desc .date {
  display: inline-block;
  width: 100%;
  margin: 0 0 13px;
  font-size: 14px;
}
.news-item .news-item-holder a .desc .name {
  width: 100%;
  height: 50px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -moz-box;
  -moz-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-clamp: 2;
  box-orient: vertical;
}
.news-item .news-item-holder a .desc .action {
  display: inline-flex;
  align-items: center;
  width: 100%;
  margin: 24px 0 0;
  color: #666666;
  transition: all 0.3s cubic-bezier(0.45, 0, 0.55, 1);
}
.news-item .news-item-holder a .desc .action .text {
  font-size: 14px;
}
.news-item .news-item-holder a .desc .action .icon {
  width: 16px;
  height: 10px;
  margin-left: 10px;
  color: #666666;
  transition: all 0.3s cubic-bezier(0.45, 0, 0.55, 1);
}
.news-item .news-item-holder a .desc .action .icon svg {
  width: 100%;
  height: 100%;
}

@media (max-width: 1470px) {
  .news-item .news-item-holder a .image {
    height: 240px;
  }
}
@media (max-width: 1279px) {
  .news-plain-list {
    width: calc(100% + 20px);
    margin-left: -10px;
    margin-bottom: -20px;
  }
  .news-plain-list .news-item {
    padding: 0 10px;
    margin: 0 0 20px;
  }
  .news-plain-list .news-item .news-item-holder a .desc {
    padding: 15px 20px 23px;
  }
  .news-item .news-item-holder a .image {
    height: 190px;
  }
  .news-item .news-item-holder a .desc {
    padding: 16px 25px 25px;
  }
  .news-item .news-item-holder a .desc .date {
    font-size: 10px;
    margin: 0 0 11px;
  }
  .news-item .news-item-holder a .desc .name {
    height: 36px;
    font-size: 14px;
    line-height: 130%;
    font-weight: 400;
  }
  .news-item .news-item-holder a .desc .action {
    margin: 17px 0 0;
  }
  .news-item .news-item-holder a .desc .action .text {
    font-size: 12px;
  }
}
@media (max-width: 767px) {
  .news-list-preview {
    overflow: hidden;
  }
  .news-list-preview .news-list-preview-holder > .action {
    margin: 6px 0 0;
  }
  .news-plain-list {
    width: 100%;
    margin-left: 0;
    margin-bottom: -15px;
  }
  .news-plain-list .news-item {
    width: 100%;
    padding: 0;
    margin: 0 0 15px;
  }
  .news-plain-list .news-item .news-item-holder a .image {
    height: 160px;
  }
  .news-plain-list .news-item .news-item-holder a .desc {
    padding: 11px 20px 16px;
  }
  .news-plain-list .news-item .news-item-holder a .desc .action {
    margin: 18px 0 0;
  }
  .news-slider .slick-slider .slick-list {
    overflow: visible;
    box-sizing: border-box;
    padding: 0 6.5% 0 0;
  }
  .news-item .news-item-holder a .image {
    height: 154px;
  }
  .news-item .news-item-holder a .desc {
    padding: 12px 20px 12px;
  }
  .news-item .news-item-holder a .desc .date {
    font-size: 12px;
    line-height: 17px;
    margin: 0 0 6px;
  }
  .news-item .news-item-holder a .desc .name {
    font-size: 16px;
    line-height: 22px;
    height: 44px;
  }
  .news-item .news-item-holder a .desc .action {
    margin: 24px 0 0;
  }
  .news-item .news-item-holder a .desc .action .text {
    font-size: 14px;
  }
  .news-item .news-item-holder a .desc .action .icon {
    width: 10px;
    height: 7px;
    margin-left: 5px;
  }
}
.plaque-links-block {
  display: inline-block;
  width: 100%;
}
.plaque-links-block .plaque-links-block-holder {
  display: inline-block;
  width: 100%;
}
.plaque-links-block .plaque-links-block-holder .plaque-link.strong .plaque-link-holder a {
  font-size: 18px;
  font-weight: bold;
}
.plaque-links-block .plaque-links-block-holder .plaque-link.strong .plaque-link-holder a .name {
  margin: 0 0 12px;
}
.plaque-links-block .plaque-links-block-holder .plaque-link.strong .plaque-link-holder a .action {
  font-size: 16px;
}
.plaque-links-block .plaque-links-block-holder .plaque-link {
  display: inline-block;
  width: 100%;
  height: 100%;
}
.plaque-links-block .plaque-links-block-holder .plaque-link .plaque-link-holder {
  display: inline-block;
  width: 100%;
  height: 100%;
}
.plaque-links-block .plaque-links-block-holder .plaque-link .plaque-link-holder a {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: #F8F8F8;
  box-sizing: border-box;
  padding: 27px 30px 30px;
  font-size: 16px;
  line-height: 140%;
  color: #1D1D1B;
}
.plaque-links-block .plaque-links-block-holder .plaque-link .plaque-link-holder a:hover .action {
  color: #056256;
  transition: all 0.3s cubic-bezier(0.45, 0, 0.55, 1);
}
.plaque-links-block .plaque-links-block-holder .plaque-link .plaque-link-holder a:hover .action .icon {
  color: #056256;
  transform: translateX(5px);
  transition: all 0.3s cubic-bezier(0.45, 0, 0.55, 1);
}
.plaque-links-block .plaque-links-block-holder .plaque-link .plaque-link-holder a .name {
  display: inline-block;
  width: 100%;
  flex-grow: 1;
  margin: 0 0 20px;
}
.plaque-links-block .plaque-links-block-holder .plaque-link .plaque-link-holder a .name.lines-2 {
  min-height: 44px;
}
.plaque-links-block .plaque-links-block-holder .plaque-link .plaque-link-holder a .action {
  display: flex;
  align-items: center;
  width: 100%;
  color: #666666;
  font-size: 14px;
  transition: all 0.3s cubic-bezier(0.45, 0, 0.55, 1);
}
.plaque-links-block .plaque-links-block-holder .plaque-link .plaque-link-holder a .action .text {
  float: left;
}
.plaque-links-block .plaque-links-block-holder .plaque-link .plaque-link-holder a .action .icon {
  float: left;
  width: 16px;
  height: 10px;
  margin-left: 10px;
  color: #666666;
  transition: all 0.3s cubic-bezier(0.45, 0, 0.55, 1);
}
.plaque-links-block .plaque-links-block-holder .plaque-link .plaque-link-holder a .action .icon svg {
  width: 100%;
  height: 100%;
}

@media (max-width: 1279px) {
  .plaque-links-block .plaque-links-block-holder .plaque-link.strong .plaque-link-holder a {
    font-size: 16px;
  }
  .plaque-links-block .plaque-links-block-holder .plaque-link.strong .plaque-link-holder a .action {
    font-size: 14px;
  }
  .plaque-links-block .plaque-links-block-holder .plaque-link .plaque-link-holder a {
    font-size: 14px;
    line-height: 130%;
    padding: 17px 25px 20px;
  }
  .plaque-links-block .plaque-links-block-holder .plaque-link .plaque-link-holder a .name {
    margin: 0 0 15px;
  }
  .plaque-links-block .plaque-links-block-holder .plaque-link .plaque-link-holder a .name.lines-2 {
    min-height: 39px;
  }
  .plaque-links-block .plaque-links-block-holder .plaque-link .plaque-link-holder a .action {
    font-size: 12px;
  }
}
@media (max-width: 767px) {
  .plaque-links-block.xs-bigger-plaque .plaque-links-block-holder .plaque-link .plaque-link-holder a {
    padding: 17px 20px 20px;
  }
  .plaque-links-block .plaque-links-block-holder .plaque-link.strong .plaque-link-holder a {
    font-size: 16px;
  }
  .plaque-links-block .plaque-links-block-holder .plaque-link.strong .plaque-link-holder a .action {
    font-size: 14px;
  }
  .plaque-links-block .plaque-links-block-holder .plaque-link .plaque-link-holder a {
    font-size: 16px;
    line-height: 22px;
    padding: 13px 20px 15px;
  }
  .plaque-links-block .plaque-links-block-holder .plaque-link .plaque-link-holder a .name {
    margin: 0 0 10px;
  }
  .plaque-links-block .plaque-links-block-holder .plaque-link .plaque-link-holder a .name.lines-2 {
    min-height: 32px;
    margin: 0 0 15px;
  }
  .plaque-links-block .plaque-links-block-holder .plaque-link .plaque-link-holder a .action {
    font-size: 14px;
  }
  .plaque-links-block .plaque-links-block-holder .plaque-link .plaque-link-holder a .action .icon {
    width: 11px;
    height: 6px;
    margin-left: 5px;
  }
}
.small-articles-block {
  display: inline-block;
  width: 100%;
}
.small-articles-block .small-articles-block-holder {
  display: inline-block;
  width: 100%;
  box-sizing: border-box;
  padding: 30px;
}
.small-articles-block .small-articles-block-holder .heading {
  display: inline-block;
  width: 100%;
  margin: 0 0 29px;
}
.small-articles-block .small-articles-block-holder .small-articles-list {
  display: inline-block;
  width: 100%;
}
.small-articles-block .small-articles-block-holder .small-articles-list .small-articles-list-holder {
  display: inline-block;
  width: 100%;
}
.small-articles-block .small-articles-block-holder .small-articles-list .small-articles-list-holder .small-article {
  display: inline-block;
  width: 100%;
}
.small-articles-block .small-articles-block-holder .small-articles-list .small-articles-list-holder .small-article:not(:last-of-type) {
  margin: 0 0 28px;
}
.small-articles-block .small-articles-block-holder .small-articles-list .small-articles-list-holder .small-article .small-article-holder {
  display: inline-block;
  width: 100%;
  background-color: white;
  box-sizing: border-box;
  padding: 30px;
}
.small-articles-block .small-articles-block-holder .small-articles-list .small-articles-list-holder .small-article .small-article-holder .image {
  float: left;
  width: 400px;
}
.small-articles-block .small-articles-block-holder .small-articles-list .small-articles-list-holder .small-article .small-article-holder .image .image-holder {
  display: inline-block;
  width: 100%;
}
.small-articles-block .small-articles-block-holder .small-articles-list .small-articles-list-holder .small-article .small-article-holder .image .image-holder img {
  display: inline-block;
  width: 100%;
}
.small-articles-block .small-articles-block-holder .small-articles-list .small-articles-list-holder .small-article .small-article-holder .text {
  float: left;
  width: calc(100% - 400px);
  box-sizing: border-box;
  padding-left: 30px;
}
.small-articles-block .small-articles-block-holder .small-articles-list .small-articles-list-holder .small-article .small-article-holder .text .text-holder {
  display: inline-block;
  width: 100%;
}
.small-articles-block .small-articles-block-holder .small-articles-list .small-articles-list-holder .small-article .small-article-holder .text .text-holder p {
  display: inline-block;
  width: 100%;
}
.small-articles-block .small-articles-block-holder .small-articles-list .small-articles-list-holder .note {
  display: inline-block;
  width: 100%;
}
.small-articles-block .small-articles-block-holder .small-articles-list .small-articles-list-holder .note .note-holder {
  display: inline-block;
  width: 100%;
  background-color: white;
  border-radius: 10px;
  color: #056256;
  box-sizing: border-box;
  padding: 15px;
  font-size: 14px;
  line-height: 20px;
}
.small-articles-block .small-articles-block-holder .small-articles-list .small-articles-list-holder .note .note-holder .icon {
  float: left;
}
.small-articles-block .small-articles-block-holder .small-articles-list .small-articles-list-holder .note .note-holder .icon svg {
  width: 24px;
  height: 24px;
}
.small-articles-block .small-articles-block-holder .small-articles-list .small-articles-list-holder .note .note-holder .text {
  float: left;
  width: calc(100% - 24px);
  box-sizing: border-box;
  padding-left: 10px;
}

@media (max-width: 1279px) {
  .small-articles-block .small-articles-block-holder {
    padding: 25px;
  }
  .small-articles-block .small-articles-block-holder .heading {
    margin: 0 0 23px;
  }
  .small-articles-block .small-articles-block-holder .small-articles-list .small-articles-list-holder .small-article:not(:last-of-type) {
    margin: 0 0 18px;
  }
  .small-articles-block .small-articles-block-holder .small-articles-list .small-articles-list-holder .small-article .small-article-holder {
    padding: 20px;
  }
  .small-articles-block .small-articles-block-holder .small-articles-list .small-articles-list-holder .small-article .small-article-holder .image {
    width: 195px;
  }
  .small-articles-block .small-articles-block-holder .small-articles-list .small-articles-list-holder .small-article .small-article-holder .text {
    width: calc(100% - 195px);
    padding-left: 20px;
  }
  .small-articles-block .small-articles-block-holder .small-articles-list .small-articles-list-holder .note .note-holder {
    font-size: 12px;
    line-height: 17px;
  }
}
@media (max-width: 767px) {
  .small-articles-block .small-articles-block-holder {
    width: 110.35%;
    margin-left: -5.175%;
    padding: 30px 5.175%;
  }
  .small-articles-block .small-articles-block-holder .heading {
    margin: 0 0 20px;
  }
  .small-articles-block .small-articles-block-holder .small-articles-list .small-articles-list-holder .small-article:not(:last-of-type) {
    margin: 0 0 18px;
  }
  .small-articles-block .small-articles-block-holder .small-articles-list .small-articles-list-holder .small-article:not(:last-of-type) .small-article-holder {
    padding: 0;
    background-color: transparent;
  }
  .small-articles-block .small-articles-block-holder .small-articles-list .small-articles-list-holder .small-article:not(:last-of-type) .small-article-holder .image {
    width: 100%;
    margin: 0 0 20px;
  }
  .small-articles-block .small-articles-block-holder .small-articles-list .small-articles-list-holder .small-article:not(:last-of-type) .small-article-holder .text {
    width: 101%;
    padding: 0;
  }
  .small-articles-block .small-articles-block-holder .small-articles-list .small-articles-list-holder .note .note-holder {
    padding: 5px;
    font-size: 12px;
    line-height: 17px;
  }
  .small-articles-block .small-articles-block-holder .small-articles-list .small-articles-list-holder .note .note-holder .icon svg {
    width: 16px;
    height: 16px;
  }
  .small-articles-block .small-articles-block-holder .small-articles-list .small-articles-list-holder .note .note-holder .text {
    width: calc(100% - 16px);
    padding-left: 5px;
  }
}
.common-single-text-block.with-side-image .common-single-text-block-holder .image {
  width: 400px;
  float: left;
}
.common-single-text-block.with-side-image .common-single-text-block-holder .image .image-holder {
  display: inline-block;
  width: 100%;
  overflow: hidden;
}
.common-single-text-block.with-side-image .common-single-text-block-holder .image .image-holder img {
  width: 100%;
}
.common-single-text-block.with-side-image .common-single-text-block-holder .text {
  width: calc(100% - 400px);
  box-sizing: border-box;
  padding-left: 30px;
}
.common-single-text-block {
  display: inline-block;
  width: 100%;
}
.common-single-text-block .common-single-text-block-holder {
  display: inline-block;
  width: 100%;
  box-sizing: border-box;
  padding: 30px;
}
.common-single-text-block .common-single-text-block-holder .inline-link-list ul, .common-single-text-block .common-single-text-block-holder .inline-link-list ol {
  color: #056256;
  margin-bottom: 0;
}
.common-single-text-block .common-single-text-block-holder .inline-link-list ul:not(:last-of-type), .common-single-text-block .common-single-text-block-holder .inline-link-list ol:not(:last-of-type) {
  margin-bottom: 15px;
}
.common-single-text-block .common-single-text-block-holder .inline-link-list ul li, .common-single-text-block .common-single-text-block-holder .inline-link-list ol li {
  margin: 0;
}
.common-single-text-block .common-single-text-block-holder .inline-link-list ul li:not(:last-of-type), .common-single-text-block .common-single-text-block-holder .inline-link-list ol li:not(:last-of-type) {
  margin: 0 0 15px;
}
.common-single-text-block .common-single-text-block-holder .text {
  float: left;
  width: 100%;
}
.common-single-text-block .common-single-text-block-holder .text .text-holder {
  display: inline-block;
  width: 100%;
}
.common-single-text-block .common-single-text-block-holder .text .text-holder h3, .common-single-text-block .common-single-text-block-holder .text .text-holder .h3 {
  margin: 0 0 30px;
}
.common-single-text-block .common-single-text-block-holder .text .text-holder ul, .common-single-text-block .common-single-text-block-holder .text .text-holder ol {
  margin: 0;
}
.common-single-text-block .common-single-text-block-holder .text .text-holder ul:not(:last-child), .common-single-text-block .common-single-text-block-holder .text .text-holder ol:not(:last-child) {
  margin: 0 0 15px;
}
.common-single-text-block .common-single-text-block-holder .text .text-holder ul li, .common-single-text-block .common-single-text-block-holder .text .text-holder ol li {
  margin: 0;
}
.common-single-text-block .common-single-text-block-holder .text .text-holder ul li:not(:last-of-type), .common-single-text-block .common-single-text-block-holder .text .text-holder ol li:not(:last-of-type) {
  margin: 0 0 10px;
}
.common-single-text-block .common-single-text-block-holder .text .text-holder .external-link {
  display: inline-block;
  width: 100%;
  margin: 35px 0 0;
}
.common-single-text-block .common-single-text-block-holder .text .text-holder .external-link a {
  font-size: 18px;
}
.common-single-text-block .common-single-text-block-holder .text .text-holder .action {
  display: inline-block;
  width: 100%;
  margin: 40px 0 0;
}
.common-single-text-block .common-single-text-block-holder .text .text-holder .action .btn {
  min-width: 212px;
  text-align: center;
}

@media (max-width: 1279px) {
  .common-single-text-block.with-side-image .common-single-text-block-holder .image {
    width: 195px;
  }
  .common-single-text-block.with-side-image .common-single-text-block-holder .text {
    width: calc(100% - 195px);
  }
  .common-single-text-block .common-single-text-block-holder {
    padding: 25px;
  }
  .common-single-text-block .common-single-text-block-holder .text .text-holder h3, .common-single-text-block .common-single-text-block-holder .text .text-holder .h3 {
    margin: 0 0 20px;
  }
  .common-single-text-block .common-single-text-block-holder .text .text-holder ul li:not(:last-of-type), .common-single-text-block .common-single-text-block-holder .text .text-holder ol li:not(:last-of-type) {
    margin: 0;
  }
  .common-single-text-block .common-single-text-block-holder .text .text-holder .external-link {
    margin: 25px 0 0;
  }
  .common-single-text-block .common-single-text-block-holder .text .text-holder .external-link a {
    font-size: 16px;
  }
  .common-single-text-block .common-single-text-block-holder .text .text-holder .action {
    margin: 30px 0 0;
  }
  .common-single-text-block .common-single-text-block-holder .text .text-holder .action .btn {
    min-width: 205px;
  }
}
@media (max-width: 767px) {
  .common-single-text-block.with-side-image .common-single-text-block-holder .image {
    width: 100%;
    margin: 0 0 10px;
  }
  .common-single-text-block.with-side-image .common-single-text-block-holder .text {
    width: 100%;
    padding: 0;
  }
  .common-single-text-block .common-single-text-block-holder {
    padding: 0;
  }
  .common-single-text-block .common-single-text-block-holder.bg-secondary {
    background-color: white;
  }
  .common-single-text-block .common-single-text-block-holder .text .text-holder h3, .common-single-text-block .common-single-text-block-holder .text .text-holder .h3 {
    margin: 0 0 15px;
  }
  .common-single-text-block .common-single-text-block-holder .text .text-holder .external-link {
    margin: 17px 0 0;
  }
  .common-single-text-block .common-single-text-block-holder .text .text-holder .external-link a {
    font-size: 14px;
  }
  .common-single-text-block .common-single-text-block-holder .text .text-holder .action {
    margin: 20px 0 0;
  }
  .common-single-text-block .common-single-text-block-holder .text .text-holder .action .btn {
    width: 100%;
    font-size: 16px;
    line-height: 22px;
    padding: 8px 10px;
  }
}