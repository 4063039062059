.empty-search {
    display: inline-block;
    width: 100%;
    .empty-search-holder {
        display: inline-block;
        width: 100%;
        padding: 138px 0 140px;
        .text {
            display: inline-block;
            width: 100%;
            text-align: center;
            font-weight: 600;
        }
    }
}

@import "../../media/tablet/includes/common/empty_search";
@import "../../media/mobile/includes/common/empty_search";
