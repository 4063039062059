.application-form {
    display: inline-block;
    width: 100%;
    .application-form-holder {
        display: inline-block;
        width: 100%;
        background-color: white;
        box-sizing: border-box;
        overflow: visible;
        padding: 80px 40px;
        form {
            display: block;
            margin: 0 auto;
            width: 52.5%;
            background-color: #e4e5e9;
            border-radius: 15px;
            overflow: visible;
            box-sizing: border-box;
            padding: 35px 47px 35px;
            .form-group {
                .form-col {

                }
            }

            .action {
                .submit {
                    display: block;
                    margin: 0 auto;
                    width: 235px;
                    float: none;
                    .btn {
                        border-radius: 5px;
                        font-size: 21px;
                        color: white;
                        background-color: #777777;
                        border-color: #777777;
                        &:hover {
                            background-color: $brand-primary;
                            border-color: $brand-primary;
                        }
                    }
                }
            }

            .checkbox-list {
                display: inline-block;
                width: 100%;
                margin: 0 0 9.5px;
                .heading {
                    display: inline-block;
                    width: 100%;
                    font-size: 14px;
                    line-height: 22px;
                    letter-spacing: -0.25px;
                    margin: 0 0 3px;
                }

                .checkbox-item {
                    display: inline-block;
                    width: 100%;
                    .form-field {
                        margin: 0;
                    }
                }
            }

            .form-field {
                margin: 0 0 9.5px;

                label {
                    .name {
                        font-size: 14px;
                        line-height: 22px;
                        letter-spacing: -0.25px;
                        margin: 0 0 3px;
                    }

                    input[type="file"] {
                        margin: 5px 0 7px;
                    }
                }

                label.checkcontainer {
                    input {

                    }  
                    
                    .checkmark {
                        width: 15px;
                        height: 15px;
                        margin: 4px 0 0;
                        border-radius: 5px;
                    }

                    .name {
                        font-size: 18px;
                        line-height: 22px;
                        margin: 0 0 0 10px;
                    }
                }

                label.select {
                    .selectric-selectric-select {
                        .selectric {
                            border-color: #BABABA;
                            height: 50px;
                            border-radius: 5px;
                            .label {
                                line-height: 50px;
                                font-size: 18px;
                                margin: 0 45px 0 15px;
                            }
                        }
                    }
                }

                label.input {
                    &.captcha {
                        input {
                            width: 249px;
                            float: left;
                            height: 35px;
                            padding: 0 15px 0 7px;
                        }

                        .captcha-image {
                            float: left;
                            margin-left: 6px;
                            margin-top: 3px;
                            img {
                                height: 30px;
                                width: auto;
                            }
                        }
                    }
                    input, textarea {
                        border-radius: 5px;
                        color: $gray-base;
                        border-color: #BABABA;
                        height: 50px;
                        padding: 0 15px 0 15px;
                        font-size: 18px;
                        line-height: 50px;
                        &:focus {
                            border-color: $brand-primary;
                        }
                        @include placeholder {
                            color: #A5A5A5;
                            font-size: 18px;
                            line-height: 50px;
                        }
                    }

                    textarea {
                        line-height: 27px;
                        padding: 15px;
                        height: 96px;
                    }
                }
            }
        }
    }
}

@import "../../media/tablet/includes/common/application_form";
@import "../../media/mobile/includes/common/application_form";
