.links-buttons-block {
    display: inline-block;
    width: 100%;
    .links-buttons-block-holder {
        display: inline-block;
        width: 100%;
        box-sizing: border-box;
        padding: 30px;
        .heading {
            display: inline-block;
            width: 100%;
            margin: 0 0 33px;
            h3, .h3 {

            }
        }
        ul {
            display: inline-block;
            padding: 0;
            list-style: none;
            margin: 0 -4px -14px;
            li {
                float: left;
                padding: 0 4px 14px;
                a {
                    padding: 8.5px 20px;
                    font-weight: 400;
                }
            }
        }
    }
}

@import "../../media/tablet/includes/common/links_buttons";
@import "../../media/mobile/includes/common/links_buttons";
