@media (max-width: $screen-sm) {
    // sm-view
    .bulletin-section {
        .bulletin-section-holder {
       
        }
    }

    .bulletin-tabs-block.xs-reverse {
        margin: -15px 0 0;
        .bulletin-tabs-block-holder { 
            background-color: white;
            .bulletin-tabs-controlls { 
                margin: 0;
                .bulletin-tabs-controlls-holder { 
                    .controller-link { 
                        a {
                            background-color: $bg-secondary;
                            border-color: $bg-secondary;
                            &.current {
                                background-color: $brand-primary;
                                border-color: $brand-primary;
                                color: white;
                            }
                        }
                    }
                }
            }
        }

        .inner-tab { 
            .inner-tab-holder { 
    
                .plaque-links-block {
                    .plaque-links-block-holder {
                        .plaque-link {
                            .plaque-link-holder {
                                a {
                                    background-color: $bg-secondary;
                                }
                            }
                        }
                    }
                }

                >.text.variant-2 {
                    padding: 17px 19px 20px;
                    background-color: $bg-secondary;
                    .plaque-links-block {
                        .plaque-links-block-holder {
                            .plaque-link {
                                .plaque-link-holder {
                                    a {
                                        background-color: white;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    } 
    .bulletin-tabs-block { 
        .bulletin-tabs-block-holder { 
            width: 110.35%;
            margin-left: -5.175%;
            box-sizing: border-box;
            padding: 15px 5.175%;
            .bulletin-tabs-controlls { 
                .bulletin-tabs-controlls-holder { 
                    width: 100%;
                    margin: 0;
                    .controller-link { 
                        margin: 0 0 3px;
                        width: 100%;
                        a {
                            width: 100%;
                            font-size: 14px;
                            line-height: 20px;
                            padding: 10.5px 15px;
                        }
                    }
                }
            }

            .bulletin-tabs { 
                .tabs { 
                    width: 110.35%;
                    margin-left: -5.175%;
                    box-sizing: border-box;
                    .tab {
                        padding: 15px 5.175% 0;
                        box-sizing: border-box;
                    }
                }
            }
        }
    }

    .inner-tab { 
        .inner-tab-holder { 

            .plaque-links-block {
                .plaque-links-block-holder {
                    .plaque-link {
                        .plaque-link-holder {
                            a {
                                background-color: white;
                            }
                        }
                    }
                }
            }

            .iconed-links-block { 
                margin: 0 0 20px;
                .iconed-links-block-holder { 
                    .link-item { 
                        .link-item-holder { 
                            .link { 
                                padding: 15px 25px 15px;
                            }
                        }
                    }
                }
            }

            .reviews-slider {
                .reviews-slider-holder {
                    &.slick-slider {
                        .slick-list {
                            overflow: visible;
                        }
                    }
                    .review-slide-item {
                        .review-slide-item-holder {
                            height: 380px;
                        }
                    }
                }
            }

            .about-text {
                .about-text-holder { 
                    background-color: transparent;
                    padding: 0;
                    display: flex;
                    flex-direction: column-reverse;
                    .image { 
                        width: 100%;
                        margin: 0;
                    }

                    .text-desc { 
                        width: 100%;
                        padding: 0;
                        margin: 0 0 20px;
                        .text-desc-holder { 
                            h3, .h3 {
                                margin: 0 0 20px;
                            }

                            .author { 
                                margin: 15px 0 0;
                                .name { 
                                    margin: 0 0 10px;
                                }
                            }

                            .action { 
                                margin: 20px 0 0;
                                .btn {
                                    min-width: auto;
                                    width: 100%;
                                }
                            }
                        }
                    }
                }
            }

            .link-buttons-block {
                .link-buttons-block-holder { 
                    background-color: transparent;
                    padding: 0;
                    overflow: visible;
                    .heading { 
                        margin: 0 0 20px;
                    }

                    .note { 
                        margin: 0 0 20px;
                    }

                    .button-links { 
                        width: calc(100% + 5px);
                        margin-bottom: -5px;
                        .btn { 
                            margin-right: 5px;
                            margin-bottom: 5px;
                            background-color: white;
                            border-color: white;
                            padding: 5px 15px;
                            font-size: 14px;
                            width: 100%;
                        }
                    }
                } 
            }

            .iframe-block {
                .iframe-block-holder {
                    padding: 0;
                    background-color: none;
                }
            }

            >.text.variant-2 {
                padding: 0;
                background-color: transparent;
                h3, .h3 {
                    margin: 0 0 30px;
                }
                p {
                    &:not(:last-of-type) {
                        margin: 0 0 10px;
                    }
                }
            }
            >.text {
                padding: 0;
                background-color: transparent;
                .bkg-image {
                    right: 50%;
                    top: 34px;
                    transform: translateX(50%);
                }

                .heading {
                    flex-direction: column-reverse;
                    align-items: start;
                    margin: 0 0 20px;
                    .heading-text {
                        h3, .h3 {
                            line-height: 130%;
                        }
                    }

                    .author {
                        margin: 0 0 11px;
                    }
                }

                h4, .h4 {
                    margin: 0 0 10px;
                }
                
                p {
                    line-height: 22px;
                }

                .note {
                    background-color: white;
                    font-size: 14px;
                    line-height: 20px;
                    padding: 5px;
                    margin: 12px 0 0;
                    border-radius: 10px;
                    .icon {
                        width: 16px;
                        height: 16px;
                        svg {
                           
                        }
                    }

                    .text {
                        width: calc(100% - 16px);
                        box-sizing: border-box;
                        padding-left: 5px;
                    }
                }

                >.action {
                    .btn {
                        min-width: none;
                        width: 100%;
                        text-align: center;
                    }
                }
            }
        }

    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
